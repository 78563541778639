exports.idCheck = {
  serialize:
    ({ type }) =>
    (value = {}) => ({ value, type, asyncData: 'idCheck' }),
  deserialize: ({ value } = {}) => value,
  validate:
    () =>
    ({ value } = {}) => {
      if (
        Boolean(value) &&
        Boolean(value.front) &&
        (value.type === 'passport' || Boolean(value.back))
      ) {
        return null
      }
      return 'requiredField'
    },
}

import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import jmespath from 'jmespath'
import { useLazyQuery } from '@apollo/client'
import { useForm } from 'react-final-form'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { FieldView } from '../FieldView/FieldView'
import { useBindingField } from '../../hooks/useBindingField'

import {
  getInseeMunicipalitiesByPostalCodeQuery,
  getCountriesEuQuery,
  getCountriesQuery,
  getRegistrarQuery,
  getCompanyMemberRolesQuery,
  getInseeLegalFormsFrQuery,
  getProcessInstancesShortQuery,
} from '../../graphql/queries'
import { Flag } from '../../components'

interface TypeFieldSelectorProps {
  user: any
  prefix?: string
  definition: any
  name: string
  defaultValue: any
  options: any
  locale: string
  type: string
  sort?: string
  initialOptions: any
  dependency?: string
  [key: string]: any
}

export const TypeFieldSelector: FC<TypeFieldSelectorProps> = params => {
  const {
    i18n: { language: currentLanguage },
  } = useTranslation()

  const {
    user,
    prefix,
    definition,
    name,
    defaultValue,
    options: optionsProp,
    locale,
    sort,
    initialOptions,
    ...props
  } = params

  const [edgeData, setEdgeData] = useState<any[]>([])
  const [edgeOffset, setEdgeOffset] = useState(0)
  const [dataOptions] = useState(definition.dataOptions || {})
  const [fetchingAllData, setFetchingAllData] = useState(false)
  const [totalCount, setTotalCount] = useState<number | null>(null) // total count of items
  const [dependency] = useState(props.dependency || dataOptions.dependency)

  const { path, getQuery } = useMemo(() => {
    switch (dataOptions.asyncData) {
      case 'dossiers':
        return {
          path: 'getProcessInstances',
          getQuery: getProcessInstancesShortQuery,
        }
      case 'cities':
        return {
          path: 'getInseeMunicipalitiesByPostalCode',
          getQuery: getInseeMunicipalitiesByPostalCodeQuery,
        }
      case 'countriesEu':
        return { path: 'getCountriesEu', getQuery: getCountriesEuQuery }
      case 'countries':
      case 'nationality':
        return { path: 'getCountries', getQuery: getCountriesQuery }
      case 'registrar':
        return { path: 'getRegistrar', getQuery: getRegistrarQuery }
      case 'companyMemberRoles':
        return {
          path: 'getCompanyMemberRoles',
          getQuery: getCompanyMemberRolesQuery,
        }
      default:
        return {
          path: 'getInseeLegalFormsFr',
          getQuery: getInseeLegalFormsFrQuery,
        }
    }
  }, [dataOptions.asyncData])

  const [getValues, { loading, data }] = useLazyQuery(getQuery, {
    onCompleted: async fetchedData => {
      const totalCount = fetchedData[path]?.totalCount || 0
      // Add new data to existing edgeData
      if (totalCount) {
        const fetchedEdges = fetchedData[path]?.edges || []
        setTotalCount(totalCount)
        setFetchingAllData(true)
        setEdgeData(prevData => [...prevData, ...fetchedEdges])
        setEdgeOffset(prevOffset => prevOffset + fetchedEdges.length) // Increase offset by new data length
      }
    },
  })

  // Watch edgeOffset and totalCount to decide when to fetch more data
  useEffect(() => {
    // Stop fetching if we've reached or exceeded totalCount
    if (totalCount !== null && edgeData?.length < totalCount) {
      formatQuery() // Call formatQuery to fetch the next set of data
    } else {
      setFetchingAllData(false)
    }
  }, [totalCount, edgeData?.length])

  const { registerField, change, getState } = useForm()

  const options = useMemo(() => {
    if (!data && !edgeData.length) {
      return []
    }
    switch (dataOptions.asyncData) {
      case 'cities':
        return (data[path] || []).map((data: any) => ({
          label: data.label,
          value: data.inseeCode,
        }))
      case 'countriesEu':
        return (data[path] || []).map(
          ({
            isoCode2,
            label,
            [`label_${currentLanguage}`]: localLabel,
          }: any) => ({
            label: <Flag countryCode={isoCode2} label={localLabel || label} />,
            value: isoCode2,
          })
        )
      case 'dossiers':
        return edgeData.flatMap(({ node }: any) =>
          node.label && node.label !== ' '
            ? [{ label: node.label, value: node.id }]
            : []
        )
      case 'countries':
        return (data[path] || []).map(
          ({
            isoCode2,
            label,
            [`label_${currentLanguage}`]: localLabel,
          }: any) => ({
            label: <Flag countryCode={isoCode2} label={localLabel || label} />,
            stringLabel: localLabel || label,
            value: isoCode2,
          })
        )
      case 'legalFormFr':
        return (data[path] || []).map(({ id, label, category }: any) => ({
          category,
          label,
          value: id,
        }))
      case 'nationality':
        return (data[path] || []).map(({ isoCode2, nationality }: any) => ({
          label: (
            <Flag
              countryCode={isoCode2}
              label={
                nationality[currentLanguage] || nationality['fr'] || isoCode2
              }
            />
          ),
          stringLabel:
            nationality[currentLanguage] || nationality['fr'] || isoCode2,
          value: isoCode2,
        }))
      case 'registrar':{
        const registrars = data[path]
          ? [data[path]]
          : data.getRegistrars
        return registrars.map(({ registrarCode, name }: any) => ({
          label: name,
          value: registrarCode,
        }))
      }
      case 'companyMemberRoles':
        return data[path].map(
          ({
            [currentLanguage]: translatedRole,
            en: englishLabel,
            value,
          }: any) => ({
            label: translatedRole || englishLabel,
            value,
          })
        )
      default:
        return []
    }
  }, [data, path, edgeData, dataOptions.asyncData, currentLanguage])

  const formatQuery = useCallback(
    ({ value = '' } = {}) => {
      switch (dataOptions.asyncData) {
        case 'dossiers':
          getValues({
            variables: {
              offset: edgeOffset,
            },
          })
          break
        case 'countriesEu':
        case 'countries':
        case 'legalFormFr':
        case 'nationality':
          getValues()
          break
        case 'registrar':
          value ? getValues({ variables: { codeCommune: value } }) : getValues()
          break
        case 'cities':
          value && getValues({ variables: { postalCode: value.toString() } })
          break
        case 'companyMemberRoles':
          value ? getValues({ variables: { companyType: value } }) : getValues()
          break
        default:
          break
      }
    },
    [dataOptions.asyncData, edgeOffset, getValues]
  )

  const debouncedFormatQuery = useMemo(
    () => debounce(formatQuery, 300),
    [formatQuery]
  )

  useEffect(() => {
    if (dependency) {
      if (dependency.startsWith('@')) {
        let value = undefined
        try {
          value = jmespath.search(getState().values, dependency.substring(1))
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
        }
        formatQuery({ value })
        return () => void 0
      }
        const unregisterField = registerField(
          prefix ? `${prefix}.${dependency}` : dependency,
          ({ value }) => {
            debouncedFormatQuery(value)
          },
          { value: true }
        )

        return () => {
          unregisterField()
          debouncedFormatQuery.cancel()
        }
    } 
    debouncedFormatQuery()
    return () => {
      debouncedFormatQuery.cancel()
    }
  }, [])

  const { input, meta } = useBindingField({ ...params, options })

  useEffect(() => {
    const valueIsValidOption =
      Array.isArray(options) &&
      options.some(el => (el.value || el) === input.value)

    if (options && (!meta.touched || !valueIsValidOption)) {
      if (Array.isArray(options) && options.length === 1) {
        input.onChange(options[0].value || options[0])
      }
    } else if (
      meta.touched &&
      input.value !== defaultValue &&
      input.value !== '' &&
      !valueIsValidOption
    ) {
      change(name, '')
    }
  }, [options, name, meta.touched, change, input.onChange, defaultValue, input.value])

  return (
    <FieldView
      {...{
        meta,
        input,
        user,
        definition,
        loading: loading || fetchingAllData,
        options,
      }}
      {...props}
    />
  )
}

import React, { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import {
  uploadDocumentMutation,
  replaceDocumentMutation,
} from '../../graphql/mutations/document'
import { getDocumentByIdQuery } from '../../graphql/queries'

import { useProcessedFilenames } from './helpers'
import { Document, getPreviewLink } from '../../helpers/document'
import { FieldView } from '../FieldView/FieldView'
import { useBindingField } from '../../hooks/useBindingField'
import { messageError } from '../../components/Message'

interface TypeFieldUploadProps {
  name: string
  fileLanguage?: string
  stepId?: string
  signer?: string
  preview?: boolean
  processInstance?: { id: string }
  entity?: { id: string }
  filetypeId?: string
  type: string
  tags?: string[]
  signaturePosition?: string
  signatureType?: string
  signatureAnchorIgnoreIfNotPresent?: boolean
  signatureAnchorString?: string
  signatureAnchorOffset?: number
  application: any
  definition: any
}

export const TypeFieldUpload: React.FC<TypeFieldUploadProps> = params => {
  const [uploadDocument] = useMutation(uploadDocumentMutation)
  const [replaceDocument] = useMutation(replaceDocumentMutation)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { input, meta } = useBindingField(params)
  const [documentIdInitialValue, setDocumentIdInitialValue] = useState<
    string | null
  >(input?.value || null)

  const {
    application,
    name,
    fileLanguage,
    stepId,
    signer,
    preview,
    processInstance,
    entity,
    filetypeId,
    tags,
    signaturePosition,
    signatureType,
    signatureAnchorIgnoreIfNotPresent,
    signatureAnchorString,
    signatureAnchorOffset,
  } = params

  const processInstanceId = useMemo(
    () => processInstance?.id,
    [processInstance?.id]
  )

  const entityId = useMemo(() => entity?.id, [entity?.id])

  const filename = useProcessedFilenames(params)

  const metadata = useMemo(() => {
    return {
      fieldName: name,
      fileLanguage,
      filename,
      processInstanceId,
      signatureAnchorIgnoreIfNotPresent,
      signatureAnchorOffset,
      signatureAnchorString,
      signaturePosition,
      signatureType,
      signer,
      processInstanceStepId: stepId,
    }
  }, [
    filename,
    name,
    fileLanguage,
    processInstanceId,
    signatureAnchorIgnoreIfNotPresent,
    signatureAnchorOffset,
    signatureAnchorString,
    signaturePosition,
    signatureType,
    signer,
    stepId,
  ])

  const { data: { getDocumentById: document } = {} } = useQuery(
    getDocumentByIdQuery,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !input?.value || typeof input.value !== 'string',
      variables: {
        documentId: input?.value,
      },
    }
  )

  React.useEffect(() => {
    if (input.value?.fakeFile && !submitting) {
      customRequest({
        file: input.value.fakeFile, // Assuming fakeFile is a File object
        onSuccess: id => {},
      })
    }
  }, [input.value])

  const customRequest = useCallback(
    async ({
      file,
      onSuccess,
    }: {
      file: File
      onSuccess: (id: string) => void
    }) => {
      try {
        setSubmitting(true)
        if (documentIdInitialValue && document) {
          const {
            data: {
              replaceDocument: { id },
            },
          } = await (preview
            ? Promise.resolve({ data: { replaceDocument: { id: uuid() } } })
            : replaceDocument({
                variables: {
                  documentSource: { processInstanceId, entityId },
                  file,
                  id: documentIdInitialValue,
                },
              }))

          onSuccess(id)
          id !== documentIdInitialValue && setDocumentIdInitialValue(id)
          input.onChange(id)
        } else {
          const {
            data: {
              uploadDocuments: [{ id }],
            },
          } = await (preview
            ? Promise.resolve({ data: { uploadDocuments: [{ id: uuid() }] } })
            : uploadDocument({
                variables: {
                  documentSource: { processInstanceId, entityId },
                  file,
                  filetypeId,
                  flags: ['form'],
                  metadata,
                  tags,
                },
              }))
          onSuccess(id)
          id !== documentIdInitialValue && setDocumentIdInitialValue(id)
          input.onChange(id)
        }
      } catch (e) {
        messageError('Error while uploading', e)
      }

      setSubmitting(false)
    },
    [
      filetypeId,
      metadata,
      preview,
      processInstanceId,
      tags,
      uploadDocument,
      document,
      replaceDocument,
      documentIdInitialValue,
    ]
  )

  return (
    <FieldView
      getPreviewLink={(document: Document) =>
        getPreviewLink(document, application.slug)
      }
      {...{
        meta,
        input,
        submitting,
        customRequest,
        ...params,
        document,
      }}
    />
  )
}

import { JOB_TYPES } from './workflow'

export const JOB_TYPES_WITH_COMPLETING_STEP = [
  JOB_TYPES.generateDocuments,
  JOB_TYPES.inviteUsers,
  JOB_TYPES.notifications,
  JOB_TYPES.sendDocuments,
  JOB_TYPES.condition,
]

export const PROCESS_INSTANCE_LOG_TYPE = {
  ACTIVITY_TRACKED: 7,
  ADDED_MAIL: 11,
  DOCUMENT_GENERATE: 9,
  DOCUMENT_REJECTION: 6,
  DOCUMENT_UPLOAD: 8,
  DOCUMENT_VALIDATION: 5,
  FINISH_PROCESS_INSTANCE: 2,
  INCOMMING_MAIL: 3,
  NEXT_STEP_PROCESS_INSTANCE: 1,
  OUTGOING_MAIL: 4,
  SHARE_PROCESS_INSTANCE: 10,
  STICKY_NOTE: 12,
  START_PROCESS_INSTANCE: 0,
  ERROR: 13,
  STEP_ERROR: 14,
}

export const PROCESS_INSTANCE_LIST_TYPE = {
  ALL: 'all',
  ARCHIVE: 'dashboardarchive',
  DELETED: 'dashboarddeleted',
  DONE: 'dashboarddone',
  IN_PROGRESS: 'dashboard',
}

export const PROCESS_INSTANCE_LIST_TITLE = {
  ALL: 'entityDossiers.all',
  ARCHIVE: 'layout.navigation.dossiers.archive',
  DELETED: 'layout.navigation.dossiers.deleted',
  DONE: 'layout.navigation.dossiers.done',
  IN_PROGRESS: 'layout.navigation.dossiers.inProgress',
}

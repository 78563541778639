import { getView, ViewOptions } from './getView'
import { getWorkflowInfo } from './utils/getWorkflowInfo'

import { DONE } from 'data-model/src/shared/workflowProcessStep'

import getProcessInstanceStepIndex from 'data-model/src/shared/getProcessInstanceStepIndex'

interface ProcessInstance {
  id: string
  label: string
  title: string
}

interface FieldInfo {
  deadlineLabel?: string
  label?: string
}

interface Item {
  type?: string
  value?: string
  description?: string
}

interface WorkflowInfo {
  fieldsInfo: Record<string, FieldInfo>
}

interface GetViewResult {
  viewOptions: ViewOptions
}

interface CalendarDataRecord {
  date: number
  key: string
  name: string
  processInstance: ProcessInstance
  path: (string | number)[]
  [key: string]: any
}

export const getProcessInstanceCalendarDataRecords = (
  {
    workflow,
    dataResolved,
    id,
    label,
    name: title,
  }: {
    workflow: any
    dataResolved: Record<string, any>
    id: string
    label: string
    name: string
  },
  language: string
): CalendarDataRecord[] => {
  const languageName = language.toLowerCase()
  const labelName = 'label' + (languageName === 'en' ? '_' + languageName : '')

  const { fieldsInfo }: WorkflowInfo = getWorkflowInfo(
    workflow,
    dataResolved,
    language
  )

  const get = (
    data: Record<string, any> = dataResolved || {},
    mainKey: string = '',
    path: (string | number)[] = []
  ): CalendarDataRecord[] => {
    return Object.entries(data).reduce<CalendarDataRecord[]>(
      (result, [key, item]) => {
        if (Array.isArray(item)) {
          item.forEach((item, index) =>
            result.push(...get(item, key + '.', [...path, key, index]))
          )
        } else {
          const { type, value } = item as Item

          if (type && value) {
            switch (type) {
              case 'timePicker': {
                const remove = (value: string, part: string) =>
                  value.replace(part, '')

                const baseName = remove(key, 'Time')

                const dateTarget = result.find(
                  ({ key }) => remove(key, 'Date') === baseName
                )

                if (dateTarget && value && !data['description']) {
                  dateTarget.description = new Date(value).toLocaleTimeString()
                }
                break
              }
              case 'dateTimePicker':
              case 'datePicker':
                {
                  const {
                    viewOptions: {
                      [labelName]: labelLocalizedFromModel,
                      label: labelFromModel,
                    } = {},
                  }: GetViewResult = getView(key) || {}

                  const name =
                    fieldsInfo[mainKey + key]?.deadlineLabel ||
                    fieldsInfo[mainKey + key]?.label ||
                    labelLocalizedFromModel ||
                    labelFromModel

                  if (name) {
                    result.push({
                      date: new Date(value).getTime(),
                      key,
                      name,
                      processInstance: {
                        id,
                        label,
                        title,
                      },
                      path: [...path, key],
                      ...item,
                    })
                  }
                }
                break
              default:
            }
          }
        }

        return result
      },
      []
    )
  }

  return get()
}

export const getProcessInstanceProgress = (processInstance: any) => {
  const index = getProcessInstanceStepIndex(processInstance.workflow)

  const stepCount = processInstance ? processInstance.workflow.length : 0

  return (
    (processInstance.status === DONE
      ? stepCount + 1
      : Math.min(
          (index === -1 ? 0 : index) || 0,
          processInstance.workflow.length - 1
        )) / stepCount
  )
}

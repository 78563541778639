const {
  faker
} = require('@faker-js/faker');
const moment = require('moment');
const {
  DATE_TYPES
} = require('../constants/dateTypes');
const resolveDate = initialValue => {
  if (DATE_TYPES[initialValue]) {
    return DATE_TYPES[initialValue];
  } else {
    let date = moment(initialValue);
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(initialValue)) {
      date = moment(initialValue, 'DD/MM/YYYY');
    } else if (/^\d{2}:\d{2}$/.test(initialValue)) {
      date = moment(initialValue, 'hh:mm').subtract({
        hours: 1
      });
    }
    return initialValue && date.isValid() ? date.parseZone(initialValue, 'YYYY-MM-DD').toISOString(true) : null;
  }
};
exports.datetime = {
  serialize: ({
    type
  }) => value => ({
    value: resolveDate(value),
    type
  }),
  deserialize: ({
    value
  } = {}) => value ? moment(value) : null,
  validate: () => value => value && value.value ? null : 'requiredField',
  fake: faker.date.past,
  toStr: ({
    value
  }, {
    locale = 'en',
    format = 'L'
  } = {}) => value ? moment.parseZone(value).locale(locale).format(format) : ''
};
exports.file = {
  serialize: ({
    type,
    asyncData
  }) => (value = {}) => ({
    value,
    type,
    asyncData
  }),
  deserialize: ({
    value
  } = {}) => value
};
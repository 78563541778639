import { parse } from 'mathjs'
import { MAX_FILE_SIZE_BYTES } from '../../constants'

export const containsSpecialChars = (str: string) =>
  /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str)

export const containsNumberChars = (str: string) => /[0-9]/.test(str)

export const emailValidation = (email: string) =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    email
  )

export const urlValidation = (url: string) =>
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
    url
  )

export const validateCalculation = (calculation: string = ''): boolean => {
  const sanitizedCalculation = calculation
    .replace(/\$\{([\w\d[\].*]+)\}/g, () => '1')
    .replace(/`/g, '') // Remove backticks

  try {
    parse(sanitizedCalculation).evaluate()
    return true
  } catch (error) {
    console.error('Math.js Evaluation Error:', error)
    return false
  }
}

export const validatePassword = (password: string) => ({
  hasCapital: password.split('').some(char => {
    if (containsSpecialChars(char)) {
      return false
    }
    if (!isNaN(Number(char))) {
      return false
    }
    return char === char.toUpperCase()
  }),
  hasTwelveChars: password.length >= 12,
  hasNumber: containsNumberChars(password),
  hasSpecial: containsSpecialChars(password),
})

export const validateMultilangAtLeastOne = (
  form: { getFieldValue: (path: string) => Record<string, string> | undefined },
  path: string
): Promise<void> => {
  const val = form.getFieldValue(path)
  if (
    val &&
    Object.values(val).length > 0 &&
    Object.values(val)[0].length > 0
  ) {
    return Promise.resolve()
  } else {
    return Promise.reject()
  }
}

export const sizeFileValidator = (
  files: FileList | File[] | null | undefined,
  t: (key: string) => string
): Promise<void> => {
  const withinSize = (files?.[0]?.size ?? 0) < MAX_FILE_SIZE_BYTES
  return withinSize
    ? Promise.resolve()
    : Promise.reject(t(`assetUpload.${withinSize ? 'type' : 'size'}Error`))
}

export const checkValidPassword = (password: string) => {
  const valid = validatePassword(password)
  return !!(
    valid.hasCapital &&
    valid.hasNumber &&
    valid.hasTwelveChars &&
    valid.hasSpecial
  )
}

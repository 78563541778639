import { isPlainObject } from 'lodash'

interface EntityValue {
  entityId?: unknown
  categoryId?: unknown
}

interface FrameValue {
  type: string
  value?: EntityValue
}

export const getDataValue = (value: unknown): unknown => {
  if (isPlainObject(value) && (value as FrameValue).type) {
    const frameValue = value as FrameValue
    switch (frameValue.type) {
      case 'entity':
      case 'member':
        if (
          frameValue.value &&
          !frameValue.value.entityId &&
          !frameValue.value.categoryId
        ) {
          return null
        }
        return frameValue
      default:
        return frameValue.value
    }
  }
  return value
}

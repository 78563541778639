import { gql } from 'graphql-tag'

export const getProductsQuery = gql`
  query getProducts {
    getProducts {
      applicationId
      category {
        id
        index
        title
        color
      }
      parentId
      description
      uploadedDocsDescription
      generatedDocsDescription
      providedDocsDescription
      icon {
        id
        url
      }
      createdAt
      id
      status
      estimatedTime
      estimatedTimeType
      price
      currency
      priceIncludesVat
      priceDescription
      steps
      stepsCount
      statsDisplay
      title
      position
      timeTracker
      process {
        id
      }
    }
  }
`

export const getProductCategoriesQuery = gql`
  query getProductCategories {
    getProductCategories {
      applicationId
      id
      title
      color
      index
    }
  }
`

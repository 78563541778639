const isString = require('lodash/isString')

exports.handleOptions = (
  optionsProp,
  { dataOptions: { options: optionsDefinition } = {} } = {},
  locale = 'en',
  { onlyLabel, onlyValue } = {}
) => {
  const options =
    (Array.isArray(optionsProp) && optionsProp) ||
    (Array.isArray(optionsDefinition) && optionsDefinition) ||
    []

  if (onlyLabel) {
    return options.every(isString)
      ? options
      : options.map(
          ({ label, [`label_${locale}`]: localeLabel }) => localeLabel || label
        )
  }

  if (onlyValue) {
    return options.every(isString) ? options : options.map(({ value }) => value)
  }

  return options.every(isString)
    ? options
    : options.map(({ value, label, [`label_${locale}`]: localeLabel }) => ({
        label: localeLabel || label,
        value,
      }))
}

export const offsetGreater = (
  startOffset: string,
  endOffset: string
): boolean => {
  const start = startOffset.split(';').map(Number)
  const end = endOffset.split(';').map(Number)

  for (let i = 0; i < Math.max(start.length, end.length); i++) {
    const startValue = start[i] || 0
    const endValue = end[i] || 0

    if (startValue > endValue) return true
    if (startValue < endValue) return false
  }

  return false
}

const {
  faker
} = require('@faker-js/faker');
const {
  isNil
} = require('lodash');
exports.select = {
  serialize: ({
    options,
    type,
    mode
  }) => value => {
    const val = value?.value || value;
    return {
      value: mode === 'multiple' && !isNil(val) ? [val] : val,
      options,
      type
    };
  },
  deserialize: ({
    value
  } = {}) => value,
  validate: () => ({
    value,
    options = []
  } = {}) => options.findIndex(option => option.value || option === value) >= 0 ? null : 'requiredField',
  fake: faker.random.arrayElement,
  toStr: ({
    value,
    options = []
  } = {}, {
    locale = 'en'
  } = {}) => {
    const formatValue = val => {
      const res = options.find(option => (option.value !== undefined ? option.value : option) === val) || {};
      return res[`label_${locale.toLowerCase()}`] || res.label || '';
    };
    if (Array.isArray(value)) {
      return value.map(val => formatValue(val)).join(', ');
    }
    return formatValue(value);
  }
};
import { isJSON } from '../text'

export interface FrameItem {
  value: string | boolean | number | { entityId?: unknown }
  type: string
}

export const isValidFrameItem = ({ value, type }: FrameItem): boolean =>
  (typeof value === 'string' && !isJSON(value) && value.length > 0) ||
  typeof value === 'boolean' ||
  typeof value === 'number' ||
  ['multiple', 'dossiers', 'idCheck', 'divider'].includes(type) ||
  (type === 'entity' &&
    typeof value === 'object' &&
    value !== null &&
    'entityId' in value)

import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { Filter } from './components/Filter'
import './SearchBarWithFilter.less'
import { SearchBar } from '../SearchBar/SearchBar'
import { useRouteQuery } from '../../hooks'

interface SearchBarWithFilterProps {}

export const SearchBarWithFilter: React.FC<
  SearchBarWithFilterProps
> = props => {
  const history = useHistory()

  const { search, sortBy, sortDirection } = useRouteQuery()

  const onChange = useCallback(
    (value: Record<string, string>) => {
      const params = new URLSearchParams()

      if (search) params.set('search', search)
      if (sortBy) params.set('sortBy', sortBy)
      if (sortDirection) params.set('sortDirection', sortDirection)

      Object.keys(value).forEach(prop => {
        params.set(prop, value[prop])
      })

      history.replace({
        ...history.location,
        search: params.toString(),
      })
    },
    [history, search, sortBy, sortDirection]
  )

  return (
    <SearchBar {...props}>
      <Filter onChange={onChange} />
    </SearchBar>
  )
}

export const colorTheme = {
  primaryColorGrape: '#390166',
}

export enum ColorSelection {
  color1 = '#ab3cde',
  color2 = '#4359d8',
  color3 = '#ffbd39',
  color4 = '#3ac9a5',
  color5 = '#390166',
}

// Generated with https://isotropic.co/tool/hex-color-to-css-filter/
export enum FilterSelection {
  color1 = 'invert(54%) sepia(93%) saturate(5644%) hue-rotate(262deg) brightness(88%) contrast(97%)',
  color2 = 'invert(26%) sepia(84%) saturate(2149%) hue-rotate(225deg) brightness(91%) contrast(85%)',
  color3 = 'invert(90%) sepia(33%) saturate(4165%) hue-rotate(328deg) brightness(102%) contrast(101%)',
  color4 = 'invert(72%) sepia(51%) saturate(507%) hue-rotate(112deg) brightness(86%) contrast(92%)',
  color5 = 'invert(13%) sepia(40%) saturate(7493%) hue-rotate(270deg) brightness(63%) contrast(113%)',
}

export const CALENDAR_DEADLINE_COLORS = {
  ACCOUNTING: ColorSelection.color1,
  CONTRACTUAL: ColorSelection.color2,
  FISCAL: ColorSelection.color3,
  SOCIAL: ColorSelection.color4,
  LITIGATION: ColorSelection.color5,
}

import { handleOptions } from 'data-model/src/shared/options'
import i18n from 'i18next'

interface Field {
  name: string
  type: any
  [key: string]: any
}

interface Form {
  change: (field: string, value: any) => void
}

interface FieldFrame {
  fields?: Field[]
}

export const findFieldTypeInFrame = (
  name: string,
  { fields = [] }: FieldFrame = {}
): Field | undefined => {
  const [, field = name, subField] = /(\w+)\[\d+]\.(.+)/.exec(name) || []
  const current = fields.find(({ name: fieldName }) => fieldName === field)
  return subField
    ? findFieldTypeInFrame(subField, current as FieldFrame)
    : current
}

interface UpdateFieldFormParams {
  field: string
  form: Form
  language: string
}

interface UpdateFieldFormMeta {
  type?: string
  mode?: string
  options?: any[]
}

interface UpdateFieldFormHelpers {
  dataOptions: any
  serialize: (data: any) => (value: any) => any
  fake: (value?: any) => any
}

export const updateFieldForm = (
  { field, form, language }: UpdateFieldFormParams,
  { type, mode, options: optionsProp }: UpdateFieldFormMeta,
  { dataOptions, serialize, fake }: UpdateFieldFormHelpers,
  valueProps?: any
): void => {
  const options = handleOptions(optionsProp, { dataOptions }, language)

  const value =
    valueProps !== undefined
      ? valueProps
      : fake(
          options.length > 0
            ? options.map(({ value }: any) => value)
            : undefined
        )

  form.change(
    field,
    serialize({
      ...dataOptions,
      language: i18n.language,
      options: optionsProp || [],
      mode,
      type,
    })(value)
  )
}

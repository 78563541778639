import React, { useEffect, useRef, useState } from 'react'
import './EllipsisTooltip.less'
import classNames from 'classnames'
import { Tooltip, TooltipProps } from '../Tooltip/Tooltip'

interface EllipsisTooltipProps extends TooltipProps {
  limit?: number
  line?: number
}

export const EllipsisTooltip: React.FC<EllipsisTooltipProps> = ({
  children,
  limit = 200,
  line,
  placement,
}) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const ref = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    const checkTruncation = () => {
      if (ref.current) {
        if (line) {
          const lineHeight = parseFloat(
            getComputedStyle(ref.current).lineHeight
          )
          const maxHeight = line * lineHeight

          setIsTruncated(ref.current.scrollHeight > maxHeight)
        } else {
          setIsTruncated(ref.current.scrollWidth > ref.current.clientWidth)
        }
      }
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)
    return () => window.removeEventListener('resize', checkTruncation)
  }, [limit, line])

  return (
    <Tooltip
      title={isTruncated && children}
      placement={placement}
      styles={{
        root: {
          visibility: isTruncated ? 'visible' : 'hidden',
        },
      }}
    >
      <span
        ref={ref}
        className={classNames(
          'ellipsis-tooltip',
          line && `ellipsis-tooltip--lined`
        )}
        style={{
          maxWidth: `${limit}px`,
          WebkitLineClamp: line ? line : 'unset',
          whiteSpace: line ? 'normal' : 'nowrap',
        }}
      >
        {children}
      </span>
    </Tooltip>
  )
}

import { gql } from 'graphql-tag'

export const assignProcessInstanceToEmailIdMutation = gql`
  mutation ($processInstanceId: ID!, $emailId: ID!) {
    assignProcessInstanceToEmailId(
      processInstanceId: $processInstanceId
      emailId: $emailId
    ) {
      id
    }
  }
`

export const setProcessInstanceStepMutation = gql`
  mutation ($id: ID!, $index: Int!) {
    setProcessInstanceStep(id: $id, index: $index) {
      id
    }
  }
`

export const runProcessInstanceServiceMutation = gql`
  mutation ($id: ID!, $index: Int!) {
    runProcessInstanceService(id: $id, index: $index) {
      id
    }
  }
`

export const rejectProcessInstanceStepMutation = gql`
  mutation ($processInstanceId: ID!, $stepId: ID!) {
    rejectProcessInstanceStep(
      processInstanceId: $processInstanceId
      stepId: $stepId
    ) {
      id
      workflow {
        id
        status
      }
      dataResolved
    }
  }
`

export const createProcessInstanceMutation = gql`
  mutation CreateProcessInstance($input: CreateProcessInstanceInput!) {
    createProcessInstance(input: $input) {
      id
      workflow {
        id
        type
      }
    }
  }
`

export const updateProcessInstanceDataMutation = gql`
  mutation ($data: JSONObject!, $id: ID!, $autoSave: Boolean) {
    updateProcessInstanceData(data: $data, id: $id, autoSave: $autoSave) {
      id
      status
      dataResolved
    }
  }
`

export const updateProcessInstanceWorkflowMutation = gql`
  mutation ($processInstanceId: ID!, $workflow: JSON!) {
    updateProcessInstanceWorkflow(id: $processInstanceId, workflow: $workflow) {
      id
    }
  }
`

export const validateProcessInstanceStepMutation = gql`
  mutation ($processInstanceId: ID!, $stepId: ID!) {
    validateProcessInstanceStep(
      processInstanceId: $processInstanceId
      stepId: $stepId
    ) {
      id
      workflow {
        id
        status
        configResolved
      }
      status
      dataResolved
    }
  }
`

import { clone, set } from 'lodash'

// Define the structure of the SFDT document
interface SFDTDocument {
  imgs?: Record<string, any>
  [key: string]: any
}

export const convertTagsToEJS = (sfdt: SFDTDocument): SFDTDocument => {
  const result: SFDTDocument = clone(sfdt)

  // Helper function to recursively traverse all nodes and update based on conditions
  const traverseAndUpdate = (
    node: any,
    path: (string | number)[] = []
  ): void => {
    if (!node || typeof node !== 'object') {
      return
    }

    // Check if the current node has 'img' and 'at' and update accordingly
    if (node.img && node.at) {
      set(result, path, {
        cf: { bi: false, ...node.cf },
        tlp: node.at,
      })

      // Ensure that the image key is removed from 'imgs'
      if (result.imgs && node.img in result.imgs) {
        delete result.imgs[node.img]
      }
    }

    // Recursively traverse deeper into the node object/array
    for (const key in node) {
      if (Array.isArray(node[key])) {
        node[key].forEach((childNode: any, index: number) => {
          traverseAndUpdate(childNode, [...path, key, index])
        })
      }
    }
  }

  // Start traversal from the root of the result object
  traverseAndUpdate(result)
  return result
}

interface User {
  role: {
    features: string[] | null
  }
}

/**
 * Hook to know if user has permission for a given feature.
 * If an array of features is given, it checks for one feature in the features.
 *
 * @param {User} user Current user logged
 * @param {string | string[]} features Features to check
 * @returns {boolean} Whether the user has permission for the given feature(s)
 */
export const usePermissions = (
  user: User,
  features: string | string[]
): boolean => {
  const userFeatures = user?.role?.features

  const featureList = Array.isArray(features) ? features : [features]

  if (!userFeatures) {
    return false
  } else {
    return (
      userFeatures.includes('superadmin') ||
      featureList.some(feature => userFeatures.includes(feature))
    )
  }
}

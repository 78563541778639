import { isNil, pick } from 'lodash'
import React from 'react'
import { useField, FieldRenderProps } from 'react-final-form'
import i18n from 'i18next'

interface BindingFieldDefinition {
  fieldOptions?: FieldOptions
  serialize: (options: any) => (input: any) => any
  deserialize: (value: any) => any
  validate: (options: any) => (value: any) => any
  dataOptions?: any
}

interface FieldOptions {
  defaultValue?: any
  [key: string]: any
}

interface UseBindingFieldProps {
  defaultValue?: any
  definition: BindingFieldDefinition
  name: string
  type?: string
  options?: any[]
  value?: any
  required?: boolean
  initialOptions?: any[]
  [key: string]: any
}

export const useBindingField = ({
  defaultValue: defaultValueProps,
  definition: {
    fieldOptions: fieldOptionsProps,
    serialize,
    deserialize,
    validate: validateProps,
    dataOptions,
  },
  name,
  type,
  options,
  value,
  required,
  initialOptions,
  ...props
}: UseBindingFieldProps): FieldRenderProps<any, HTMLElement> => {
  const [{ defaultValue: defaultValueOptions = '', ...fieldOptions }] =
    React.useState<FieldOptions>(fieldOptionsProps || {})

  const [dataOptionsFromForm] = React.useState(
    pick(props, ['currency', 'min', 'entityTypes', 'prefixField'])
  )

  const parse = React.useCallback(
    (input: any) =>
      serialize({
        ...dataOptions,
        ...dataOptionsFromForm,
        language: i18n.language,
        options: initialOptions || options || [],
        type,
      })(input),
    [dataOptions, dataOptionsFromForm, initialOptions, options, serialize, type]
  )

  const defaultValueRaw = React.useMemo(() => {
    if (defaultValueProps === '' || isNil(defaultValueProps)) {
      return defaultValueOptions || ''
    }
    return defaultValueProps || ''
  }, [defaultValueOptions, defaultValueProps])

  const defaultValue = React.useMemo(
    () => (value !== undefined ? value : parse(defaultValueRaw)),
    [defaultValueRaw, parse, value]
  )

  const validate = React.useCallback(
    (value: any) => (required !== false ? validateProps(options)(value) : null),
    [options, required, validateProps]
  )
  const fieldRenderProps = useField(name, {
    ...fieldOptions,
    defaultValue,
    format: deserialize,
    parse,
    validate,
  })

  React.useEffect(() => {
    if (
      !fieldRenderProps.meta.modified &&
      fieldRenderProps.meta.dirtySinceLastSubmit
    ) {
      fieldRenderProps.input.onChange(defaultValueRaw)
    }
  }, [])

  return fieldRenderProps
}

import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Row } from 'antd'

import {
  getEntityQuery,
  getEntityCategoriesQuery,
  getEntitiesByCategoryNameQuery,
} from '../../graphql/queries'

const FormFieldCheckConditional = lazy(() =>
  import('../FormField/FormFieldCheckConditional').then(module => ({
    default: module.FormFieldCheckConditional,
  }))
)

import { FieldView } from '../FieldView/FieldView'
import { useBindingField } from '../../hooks/useBindingField'
import { Loader } from '../../components'
import { get } from 'lodash'

interface Field {
  name: string
  fields?: Field[]
}

interface EntityForm {
  fields: Field[]
}

interface TypeFieldEntityProps {
  entityCategories?: string[]
  name: string
  frame: any
  user: any
  userId?: string
  processInstance?: any
  application?: any
  type: string
  stepId?: string
  locale: string
  input?: any
  meta?: any
  definition: any
}

export const TypeFieldEntity: React.FC<TypeFieldEntityProps> = params => {
  const translation = useTranslation()
  const { input, meta } = useBindingField(params)
  const { entityCategories: entityCategoriesFilter, name, locale } = params

  const [localEntity, setLocalEntity] = useState<any>(null)
  const [entityForm, setEntityForm] = useState<EntityForm | null>(null)
  const [loadingEntityForm, setLoadingEntityForm] = useState<boolean>(false)
  const [formReady, setFormReady] = useState<boolean>(false)

  const form = useForm()

  const {
    data: { getEntityCategories: allEntityCategories = [] } = {},
    loading: loadingEntityCategories,
  } = useQuery(getEntityCategoriesQuery, { fetchPolicy: 'cache-and-network' })

  const {
    data: { getEntitiesByCategoryName: entities = [] } = {},
    loading: loadingEntities,
  } = useQuery(getEntitiesByCategoryNameQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { categoryName: entityCategoriesFilter },
  })

  const [getEntity] = useLazyQuery(getEntityQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (
      !Array.isArray(entityCategoriesFilter) ||
      !entityCategoriesFilter.length
    ) {
      throw new Error(
        '[form] no entity types defined. You need to set the entityCategories array property'
      )
    }
  }, [])

  function updateFormFieldsRecursively(
    form: any,
    data: any,
    multiplePrefix = '',
    currentPath = ''
  ) {
    const constructPath = (key: string) =>
      multiplePrefix
        ? `${multiplePrefix}.${currentPath}${currentPath ? '.' : ''}${key}`
        : `${currentPath}${currentPath ? '.' : ''}${key}`

    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        const arrayPath = `${currentPath}[${index}]`
        updateFormFieldsRecursively(form, item, multiplePrefix, arrayPath)
      })
    } else if (typeof data === 'object' && data !== null) {
      Object.entries(data).forEach(([key, value]) => {
        if (value && typeof value === 'object' && 'value' in value) {
          const newPath = constructPath(key)
          form.change(newPath, value)
        }
      })
    }
  }

  const resetEntityValues = useCallback((formFields: Field[] = []) => {
    function extractPaths(fields: Field[], parentPath = ''): string[] {
      const paths: string[] = []

      fields.forEach(field => {
        const currentPath = parentPath
          ? `${parentPath}.${field.name}`
          : field.name
        if (field.name) paths.push(currentPath)
        if (Array.isArray(field.fields))
          paths.push(...extractPaths(field.fields, currentPath))
      })

      return paths
    }

    const paths = extractPaths(formFields, multiplePrefix)
    paths.forEach(path => {
      const field = get(form.getState().values, path)
      if (!field) {
        return
      }
      if (field.type === 'idCheck') {
        form.change(path, {
          ...field,
          value: { front: null, back: null, type: 'passport' },
        })
      } else {
        form.change(path, { ...field, value: null })
      }
    })
  }, [form.change, form.getState])

  const resetEntityFields = useCallback(() => {
    if (entityForm) {
      resetEntityValues(entityForm.fields)
      setEntityForm(null)
    }
  }, [entityForm, resetEntityValues])

  const fetchEntity = useCallback(
    async (id?: string) => {
      setFormReady(false)
      resetEntityFields()
      if (id) {
        setLoadingEntityForm(true)
        const { data: { getEntity: entity } = {} } = await getEntity({
          variables: { id },
        })
        if (entity) {
          const form = entity.type?.creationForm?.[0]
          resetEntityValues(form?.fields)
          setEntityForm(form || null)
          setLocalEntity(entity)
        } else {
          input.onChange({ entityId: null })
        }
        setLoadingEntityForm(false)
      }
    },
    [getEntity, resetEntityFields, input.onChange, resetEntityValues]
  )

  const fetchNewEntity = useCallback(
    (id?: string) => {
      setFormReady(false)
      resetEntityFields()
      if (id) {
        const category = allEntityCategories.find(
          (category: any) => category.id === id
        )
        if (category) {
          const form = category.type?.creationForm?.[0]
          resetEntityValues(form?.fields)
          setEntityForm(form || null)
        }
        setFormReady(true)
      }
    },
    [resetEntityFields, resetEntityValues, allEntityCategories]
  )

  const entityCategories = useMemo(
    () =>
      allEntityCategories
        .filter(({ categoryName }: { categoryName: string }) =>
          entityCategoriesFilter?.includes(categoryName)
        )
        .map((entity: any) => ({
          ...entity,
          form: entityCategoriesFilter?.[entity.typeName],
        })),
    [allEntityCategories, entityCategoriesFilter]
  )

  const multiplePrefix = useMemo(() => {
    const lastDotIndex = name.lastIndexOf('.')
    return lastDotIndex > 0 ? name.slice(0, lastDotIndex) : ''
  }, [name])

  useEffect(() => {
    if (localEntity && entityForm) {
      updateFormFieldsRecursively(
        form,
        localEntity.dataResolved,
        multiplePrefix
      )
      setFormReady(true)
    }
  }, [localEntity, entityForm])

  useEffect(() => {
    if (!input?.value?.categoryId) {fetchEntity(input?.value?.entityId)}
  }, [input?.value?.entityId])

  useEffect(() => {
    if (!input?.value?.entityId) {fetchNewEntity(input?.value?.categoryId)}
  }, [input?.value?.categoryId, fetchNewEntity, allEntityCategories.length])

  useEffect(() => {
    return () => {
      resetEntityFields()
    }
  }, [])

  return (
    <>
      <FieldView
        {...params}
        {...{ meta, translation, entities, entityCategories }}
        input={{ ...input }}
        loading={loadingEntityCategories || loadingEntities}
      />
      {loadingEntityForm && <Loader isRelative />}
      {entityForm && formReady && (
        <Row gutter={[0, 24]} style={{ width: '100%' }}>
          {(entityForm?.fields || []).map((field: any, key: number) => (
            <Suspense fallback={<Loader />} key={`${field.name}_${key}`}>
              <FormFieldCheckConditional
                {...{
                  frame: params.frame,
                  user: params.user,
                  userId: params.userId,
                  stepId: params.stepId,
                  processInstance: params.processInstance,
                  entity: localEntity,
                  application: params.application,
                  ...field,
                }}
                language={locale}
                prefix={multiplePrefix}
                name={
                  multiplePrefix
                    ? `${multiplePrefix}.${field.name}`
                    : field.name
                }
              />
            </Suspense>
          ))}
        </Row>
      )}
    </>
  )
}

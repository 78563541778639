import React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  uploadDocumentMutation,
  replaceDocumentMutation,
} from '../../graphql/mutations/document'

import { FieldView } from '../FieldView/FieldView'
import { useBindingField } from '../../hooks/useBindingField'

import { getDocumentByIdQuery } from '../../graphql/queries'
import { useProcessedFilenames } from './helpers'
import { Document, getPreviewLink } from '../../helpers/document'
import { getOrcreateFiletypeByTitleAndApplicationIdMutation } from '../../graphql/mutations/filetype'

interface TypeFieldIdCheckProps {
  name: string
  fileLanguage?: string
  stepId?: string
  signer?: string
  preview?: boolean
  type: string
  processInstance?: { id: string }
  entity?: { id: string }
  tags?: string[]
  signatureType?: string
  signaturePosition?: string
  application: any
  definition: any
}

export const TypeFieldIdCheck: React.FC<TypeFieldIdCheckProps> = params => {
  const [uploadDocument] = useMutation(uploadDocumentMutation)
  const [replaceDocument] = useMutation(replaceDocumentMutation)
  const [submitting, setSubmitting] = useState<string | false>(false)

  const { input, meta } = useBindingField(params)
  const [documentFrontId, setDocumentFrontId] = useState<string | null>(
    input?.value?.front || null
  )
  const [documentBackId, setDocumentBackId] = useState<string | null>(
    input?.value?.back || null
  )
  const [documentType, setDocumentType] = useState<string | null>(
    input?.value?.type || null
  )
  const [getOrcreateFiletypeByTitleAndApplicationId] = useMutation(
    getOrcreateFiletypeByTitleAndApplicationIdMutation
  )

  const {
    application,
    name,
    fileLanguage,
    stepId,
    signer,
    preview,
    entity,
    processInstance,
    tags,
    signatureType,
    signaturePosition,
  } = params

  const processInstanceId = useMemo(
    () => processInstance?.id,
    [processInstance?.id]
  )

  const entityId = useMemo(() => entity?.id, [entity?.id])

  const filename = useProcessedFilenames(params)

  const metadata = useMemo(
    () => ({
      fieldName: name,
      fileLanguage,
      filename,
      processInstanceId,
      signaturePosition,
      signatureType,
      signer,
      processInstanceStepId: stepId,
    }),
    [
      name,
      fileLanguage,
      filename,
      processInstanceId,
      signaturePosition,
      signatureType,
      signer,
      stepId,
    ]
  )

  const { data: { getDocumentById: documentFront } = {} } = useQuery(
    getDocumentByIdQuery,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !input?.value?.front || typeof input.value.front !== 'string',
      variables: {
        documentId: input?.value?.front,
      },
    }
  )

  const { data: { getDocumentById: documentBack } = {} } = useQuery(
    getDocumentByIdQuery,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !input?.value?.back || typeof input.value.back !== 'string',
      variables: {
        documentId: input?.value?.back,
      },
    }
  )

  const document = useMemo(
    () => ({ back: documentBack, front: documentFront }),
    [documentFront, documentBack]
  )

  React.useEffect(() => {
    if (input.value?.fakeFile && !submitting) {
      customRequest(
        'passport',
        'front'
      )({
        file: input.value.fakeFile,
        onSuccess: id => {},
      })
    }
  }, [input.value])

  const customRequest = useCallback(
    (type: string, side: 'front' | 'back') =>
      async ({
        file,
        onSuccess,
      }: {
        file: File
        onSuccess: (id: string) => void
      }) => {
        let filetypeId
        const { data } = await getOrcreateFiletypeByTitleAndApplicationId({
          variables: {
            title:
              type === 'passport'
                ? { EN: 'Passport', FR: 'Passeport' }
                : { EN: 'Id card', FR: "Carte d'identité" },
            applicationId: application.id,
          },
        })
        if (data.getOrcreateFiletypeByTitleAndApplicationId) {
          filetypeId = data.getOrcreateFiletypeByTitleAndApplicationId.id
        }
        const wrap = {
          back: {
            get: documentBackId,
            set: setDocumentBackId,
          },
          front: {
            get: documentFrontId,
            set: setDocumentFrontId,
          },
        }
        if (!wrap[side]) {
          return
        }
        try {
          setSubmitting(side)
          if (wrap[side].get && documentType === type) {
            const {
              data: {
                replaceDocument: { id },
              },
            } = await replaceDocument({
              variables: {
                documentSource: { processInstanceId, entityId },
                file,
                id: wrap[side].get,
              },
            })
            onSuccess(id)
            wrap[side].set(id)
            delete input.value?.fakeFile
            input.onChange({
              ...input.value,
              type,
              [side]: id,
            })
          } else {
            const {
              data: {
                uploadDocuments: [{ id }],
              },
            } = await uploadDocument({
              variables: {
                documentSource: { processInstanceId, entityId },
                file,
                filetypeId,
                flags: ['form'],
                metadata: { ...metadata, side },
                tags,
              },
            })
            onSuccess(id)
            wrap[side].set(id)
            delete input.value?.fakeFile
            input.onChange({
              ...input.value,
              type,
              [side]: id,
            })
          }
          setDocumentType(type)
        } catch (error) {
          console.error('Error uploading/replacing document:', error)
        }

        setSubmitting(false)
      },
    [
      metadata,
      preview,
      documentType,
      processInstanceId,
      tags,
      input,
      uploadDocument,
      replaceDocument,
      documentBackId,
      documentFrontId,
    ]
  )

  return (
    <FieldView
      getPreviewLink={(document: Document) =>
        getPreviewLink(document, application.slug)
      }
      {...{
        ...params,
        meta,
        input,
        submitting,
        customRequest,
        document,
      }}
    />
  )
}

import { message } from 'antd'

export const extractErrorMessage = (error: unknown): string => {
  if (!error) return ''

  const message = error instanceof Error ? error.message : String(error)

  return message
    .replace('GraphQL error: INTERNAL_SERVER_ERROR :', 'Error:')
    .replace('GraphQL error: UNAUTHENTICATED :', 'Authentication error:')
    .replace('Network error: Response not successful:', 'Network error:')
}

export const messageError = (msg: string, error?: unknown): void => {
  const errorMessage =
    process.env.NODE_ENV !== 'production'
      ? error
        ? `: ${extractErrorMessage(error)}`
        : ''
      : ''

  message.error(`${msg} ${errorMessage}`)
  console.error(`error: ${msg}\n${error}`)
}

export const messageSuccess = (title: string): void => {
  message.success(title)
}

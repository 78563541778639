const { faker } = require('@faker-js/faker')
const { isString } = require('lodash')

exports.text = {
  serialize:
    ({ type }) =>
    value => ({ value: value || '', type }),
  deserialize: value => (value && value.value ? value.value : ''),
  toStr: value => (value && isString(value.value) ? value.value.trim() : ''),
  validate: () => value =>
    value && isString(value.value) && value.value.trim().length > 0
      ? null
      : 'requiredField',
  fake: faker.lorem.words,
}

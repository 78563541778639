import { get, toPath } from 'lodash'
import { definitions } from 'data-model/src/lib/definitions'
import { nth } from 'data-model/src/shared/formatters'
import { resolveJmespath } from './jmespath'
import { getWorkflowInfo, Form, Field } from './utils/getWorkflowInfo'
import { getView } from './getView'
import { isValidFrameItem } from './utils/isValidFrameItem'
import { conditionIsFullfilled, Condition } from './condition'
import { cleanData } from 'data-model'

/**
 * Checks if all fields are filled based on their conditions.
 * @param fields - The fields to check.
 * @param state - The state object to evaluate against.
 * @returns Whether all fields are filled.
 */
const isFilled = (fields: any[], state: any): boolean =>
  fields.every(field => {
    if (!field.condition) {
      return true
    }

    if (
      field.condition &&
      field.condition.length &&
      !field.condition.every((cond: Condition) =>
        conditionIsFullfilled(cond, state)
      )
    ) {
      return true
    }

    if (field.type !== 'multiple') {
      return !field.required || !!state[field.name]
    }

    if (field.required && (!state[field.name] || !state[field.name].length)) {
      return false
    }

    return (state[field.name] || []).every((stateItem: any) =>
      isFilled(field.fields, stateItem || {})
    )
  })

/**
 * Collects frames based on conditions and state.
 * @param frames - The frames to process.
 * @param state - The state object to evaluate against.
 * @param result - The accumulated result.
 * @returns The collected frames.
 */
export const collectFrames = (
  frames: any[],
  state: any,
  result: any[] = []
): any[] => {
  if (!frames.length) {
    return result
  }

  const [frame, ...otherFrames] = frames
  result.push(frame)
  if (!isFilled(frame.fields, state)) {
    return result
  }

  if (!frame.condition || !frame.condition.length) {
    return collectFrames(otherFrames, state, result)
  }

  for (const condition of frame.condition) {
    if (conditionIsFullfilled(condition, state)) {
      const index = otherFrames.findIndex(
        frame => frame.id === condition.target
      )
      if (index > -1) {
        return collectFrames(otherFrames.slice(index), state, result)
      }
    }
  }

  return collectFrames(otherFrames, state, result)
}

/**
 * Gets the next frame based on the current frame ID and state.
 * @param config - The configuration object.
 * @param frameId - The current frame ID.
 * @param state - The state object to evaluate against.
 * @returns The next frame.
 */
export const getNextFrame = (
  { config }: { config: any[] },
  frameId: string,
  state: any = {}
): any => {
  const index = config.findIndex(({ id }) => frameId === id)
  return index >= 0 ? collectFrames(config.slice(index), state)[1] : undefined
}

type KeyMap = {
  [key: string]: string
}

/**
 * Replaces keys in an object or array based on a key map.
 * @param obj - The object or array to process.
 * @param keyMap - The key map for replacements.
 * @returns The object or array with replaced keys.
 */
function replaceKeys<T extends object | any[]>(obj: T, keyMap: KeyMap): T {
  if (Array.isArray(obj)) {
    return obj.map(item => replaceKeys(item, keyMap)) as T
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce(
      (acc, key) => {
        const newKey = keyMap[key] || key
        ;(acc as Record<string, any>)[newKey] = replaceKeys(
          (obj as Record<string, any>)[key],
          keyMap
        )
        return acc
      },
      {} as Record<string, any>
    ) as T
  }

  return obj
}

/**
 * Replaces condition values based on the provided data.
 * @param condition - The condition to process.
 * @param data - The data object to use for replacements.
 * @returns The condition with replaced values.
 */
function replaceConditionValues(condition: any, data: any): any {
  if (Array.isArray(condition)) {
    return condition.map(item => replaceConditionValues(item, data))
  }

  if (typeof condition === 'object' && condition !== null) {
    return Object.keys(condition).reduce(
      (acc, key) => {
        if (key === 'conditionValue' && typeof condition[key] === 'string') {
          const dataKey = condition[key]
          acc[key] = data[dataKey]?.value || condition[key]
        } else {
          acc[key] = replaceConditionValues(condition[key], data)
        }
        return acc
      },
      {} as Record<string, any>
    )
  }

  return condition
}

/**
 * Flattens valid conditions by extracting non-key fields.
 * @param validConditions - The valid conditions to flatten.
 * @returns The flattened conditions.
 */
function flattenValidConditions(validConditions: any[]): any[] {
  const flattenedConditions: any[] = []

  function extractNonKeyFields(condition: any) {
    const { condition: subConditions, op, ...rest } = condition

    const nonKeyFields = Object.keys(rest).reduce(
      (acc, key) => {
        if (!['value', 'key', 'op'].includes(key)) {
          acc[key] = rest[key]
        }
        return acc
      },
      {} as Record<string, any>
    )

    if (Object.keys(nonKeyFields).length > 0) {
      flattenedConditions.push(nonKeyFields)
    }

    if (['and', 'or'].includes(op) && Array.isArray(subConditions)) {
      subConditions.forEach(subCondition => {
        extractNonKeyFields(subCondition)
      })
    }
  }

  validConditions.forEach(condition => {
    extractNonKeyFields(condition)
  })

  return flattenedConditions
}

/**
 * Evaluates a condition and tracks valid conditions.
 * @param data - The data object to evaluate against.
 * @param condition - The condition to evaluate.
 * @returns The flattened valid conditions.
 */
export const evaluateConditionWithTracking = (
  data: any,
  condition: any
): any[] => {
  const validConditions: any[] = []

  function evaluateConditionWithValidList(
    data: any,
    condition: any,
    parent: any
  ): boolean {
    const { op, condition: subCondition = [] } = condition

    const result = conditionIsFullfilled(condition, data)
    if (result === true && !['and', 'or', 'not'].includes(op)) {
      validConditions.push({ ...(parent || {}), ...condition })
    }

    if (['and', 'or', 'not'].includes(op)) {
      subCondition.forEach((sub: Condition) =>
        evaluateConditionWithValidList(data, sub, condition)
      )
    }

    return result
  }

  const keyMapping = {
    conditionOperator: 'op',
    conditionValue: 'value',
    conditionVariable: 'key',
  }

  const replacedValues = replaceConditionValues(condition, data)
  const replacedKeys = replaceKeys(replacedValues, keyMapping)

  evaluateConditionWithValidList(data, replacedKeys, null)

  return flattenValidConditions(validConditions)
}

/**
 * Replaces wildcards in a formula with a prefix.
 * @param prefix - The prefix to use for replacement.
 * @param formula - The formula to process.
 * @returns The updated formula.
 */
export const replaceWildcardsWithPrefix = (
  prefix: string,
  formula: string = ''
): string => {
  const prefixParts = prefix.split('.')
  const prefixMap: Record<string, string> = {}

  prefixParts.forEach(part => {
    const match = part.match(/^(\w+)\[(\d+)\]$/)
    if (match) {
      const [, key, index] = match
      prefixMap[key] = index
    }
  })

  const updatedFormula = (formula || '').replace(
    /(\w+)\[(.*?)\]/g,
    (_, key, wildcard) => {
      return prefixMap[key]
        ? `${key}[${prefixMap[key]}]`
        : `${key}[${wildcard}]`
    }
  )

  return updatedFormula
}

/**
 * Adjusts data keys based on a prefix and values.
 * @param prefix - The prefix to use for adjustment.
 * @param values - The values object.
 * @param data - The data object to adjust.
 * @returns The adjusted data.
 */
export function adjustDataKeys(prefix: string, values: any, data: any): any {
  function findPath(
    obj: any,
    query: string,
    currentPath: string = ''
  ): string | null {
    let result: string | null = null

    for (const key in obj) {
      const value = obj[key]
      const path = Array.isArray(obj)
        ? `${currentPath}[*]`
        : currentPath
          ? `${currentPath}.${key}`
          : key

      if (key === query) {
        return path
      }

      if (typeof value === 'object' && value !== null) {
        result = findPath(value, query, path)
        if (result) {
          break
        }
      }
    }

    return result
  }

  function updateKey(key: string): string {
    const path = prefix ? `${prefix}.${key}` : key
    if (get(values, path) !== undefined) {
      return path
    }
    const fullPath = findPath(values, key) ?? ''
    return prefix ? replaceWildcardsWithPrefix(prefix, fullPath) : fullPath
  }

  function processCondition(condition: any): any {
    if (Array.isArray(condition)) {
      return condition.map(processCondition)
    }

    if (condition && typeof condition === 'object') {
      if (condition.condition) {
        return {
          ...condition,
          condition: processCondition(condition.condition),
        }
      }

      if (condition.key) {
        return {
          ...condition,
          key: updateKey(condition.key),
        }
      }
    }
    return condition
  }

  if (!data.condition && data.key) {
    return {
      ...data,
      key: updateKey(data.key),
    }
  }
  return {
    ...data,
    condition: processCondition(data.condition),
  }
}

export const groupDataByDivider = (fields: Field[] = []): Form[] =>
  fields.reduce((result: Form[], field: Field) => {
    if (field.type === 'divider') {
      return [
        ...result,
        {
          type: 'form', // Ensure the object conforms to the `Form` interface
          name: field.label || 'unnamed', // Provide a default name if `field.label` is undefined
          fields: [], // Initialize `fields` as an empty array
          id: result.length, // Add an `id` property (optional, depending on your use case)
          label: field.label, // Copy the `label` property from the `Field`
        },
      ]
    } else if (result.length) {
      // Safely access the last item in the `result` array and push the `field`
      const lastForm = result[result.length - 1]
      if (lastForm.fields) {
        lastForm.fields.push(field)
      } else {
        lastForm.fields = [field] // Initialize `fields` if it doesn't exist
      }
    }

    return result
  }, [] as Form[]) // Initialize `result` as an empty array of `Form` objects

function arrayToPath(array: (string | number)[]): string {
  return toPath(array)
    .map(item => (isNaN(Number(item)) ? item : `[${item}]`)) // Wrap numbers in square brackets
    .join('.')
    .replace(/\.\[/g, '[') // Fix extra dots before brackets
}

interface Data {
  [key: string]: any
}

interface FrameInfo {
  [key: string]: {
    label: string
  }
}

interface ResultItem {
  children: any[]
  key: string
  frameName: string
  label: string
}

interface Definitions {
  [key: string]: {
    dataOptions?: {
      options?: { value: string; label: string }[]
    }
  }
}

export const groupData = (
  data: Data,
  workflow: Form[] | Form,
  language: string
): ResultItem[] => {
  const dataCleaned = cleanData(data, {
    keepToStrMethod: true,
    locale: language,
  })

  const labelName =
    'label' +
    (language.toLowerCase() !== 'en' ? '_' + language.toLowerCase() : '')

  const { framesInfo }: { framesInfo: FrameInfo } = getWorkflowInfo(
    workflow,
    data,
    language
  )

  const flattenFormConfigs = (data: Form[]): Form[] =>
    data
      .filter(item => item.type === 'form') // Filter only objects with type 'form'
      .flatMap(item => item.config || []) as Form[] // Flatten the 'config' arrays

  const getFieldLabel = (field: Field, data: any): string => {
    const { type, displayName } = field
    const label = field[labelName as keyof Field]
    if (type === 'multiple' && displayName) {
      try {
        return resolveJmespath(data, displayName, language, true)
      } catch (error) {
        console.error(error)
      }
    }

    if (typeof label === 'string') {
      return label
    }

    const {
      viewOptions: {
        [labelName]: labelLocalizedFromModel,
        label: labelFromModel,
      } = {},
    } = getView(type) || {}

    return labelLocalizedFromModel || labelFromModel || field.name
  }

  const getFieldValue = (value: any, fieldName: string): any => {
    if (value?.toStr) {
      const val = value.toStr({ locale: language })

      return val &&
        typeof val === 'object' &&
        (definitions as Definitions)?.[fieldName]?.dataOptions
        ? (definitions as Definitions)?.[fieldName].dataOptions?.options?.find(
            (item: any) => item.value === value.toString()
          )?.label
        : val
    }

    return typeof value === 'object' && value !== null
      ? JSON.stringify(value)
      : value
  }

  const getChildRecurs = (
    fields: Field[],
    path: (string | number)[] = []
  ): any[] => {
    return fields.reduce((acc: any[], field: Field) => {
      const newPath = [...path, field.name]
      const dataValue = get(dataCleaned, newPath)
      const originalData = get(data, newPath)
      const value = getFieldValue(dataValue, field.name)
      let validCondition = true
      if (field.condition?.length) {
        validCondition = field.condition
          .filter(Boolean)
          .every((condition: any) =>
            conditionIsFullfilled(
              adjustDataKeys(arrayToPath(path), dataCleaned, condition),
              JSON.parse(JSON.stringify(dataCleaned))
            )
          )
      }
      if (
        field.type === 'uuid' ||
        !isValidFrameItem({ type: field.type, value }) ||
        field.visible === false ||
        !validCondition
      ) {
        return acc
      }
      const label = getFieldLabel(field, dataValue) || field.name

      if (field.type === 'multiple') {
        if (Array.isArray(dataValue)) {
          return [
            ...acc,
            ...dataValue
              .map((_: any, index: number) => ({
                children: getChildRecurs(field.fields || [], [
                  ...newPath,
                  index,
                ]),
                label: getFieldLabel(field, {
                  ...dataValue[index],
                  __index: nth(index + 1, { locale: language }),
                }),
                type: field.type,
              }))
              .filter(({ children }: any) => children.length),
          ]
        } else {
          return acc
        }
      } else {
        return [
          ...acc,
          {
            value,
            label,
            type: field.type,
            originalData,
            valueTranslated:
              typeof value === 'boolean' &&
              (value ? 'common.text.yes' : 'common.text.no'),
          },
        ]
      }
    }, [])
  }

  const formattedConfig = flattenFormConfigs(
    Array.isArray(workflow) ? workflow : [workflow]
  )

  const result = formattedConfig
    .map((item: Field, index) => ({
      children: getChildRecurs(item.fields || []),
      key: item.name + '-' + index,
      frameName: item.name,
      label: framesInfo?.[item.name]?.label || item[labelName] || item.label,
    }))
    .filter(({ children }) => children.length)

  return result
}

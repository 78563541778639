import gql from 'graphql-tag'

export const getDocumentsTagsQuery = gql`
  query getDocumentsTags($entityId: ID, $processInstanceId: ID) {
    getDocumentsTags(entityId: $entityId, processInstanceId: $processInstanceId)
  }
`

export const getDocumentByIdQuery = gql`
  query getDocumentById($documentId: ID!) {
    getDocumentById(documentId: $documentId) {
      id
      url
      filename
      mimetype
    }
  }
`

const { faker } = require('@faker-js/faker')
const { v4: uuid } = require('uuid')

exports.uuid = {
  serialize:
    ({ type, visible }) =>
    value => ({ value: Boolean(value) ? value : uuid(), type, visible }),
  deserialize: ({ value } = {}) => value,
  validate:
    () =>
    ({ value } = {}) =>
      Boolean(value) ? null : 'requiredField',
  fake: faker.datatype.uuid,
}

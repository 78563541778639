export const LS_LANGUAGE_PROPERTY_NAME = 'preferredLanguage'

export const DATE_FORMAT = 'LL'

export const LOCALE_SHORT_CODES = {
  DE: 'DE',
  EN: 'EN',
  ES: 'ES',
  FR: 'FR',
  IT: 'IT',
}

export const LOCALES = [
  {
    isoCode: 'GB',
    lang: 'English',
    locale: 'EN',
  },
  {
    isoCode: 'FR',
    lang: 'Français',
    locale: 'FR',
  },
  {
    isoCode: 'DE',
    lang: 'Deutsche',
    locale: 'DE',
  },
  {
    isoCode: 'ES',
    lang: 'Español',
    locale: 'ES',
  },
  {
    isoCode: 'IT',
    lang: 'Italiano',
    locale: 'IT',
  },
]

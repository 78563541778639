import { FC, useRef, useState, useEffect, useMemo } from 'react'

import { getLayoutRoot } from '../../helpers/getLayoutRoot'

import { scrollToTop } from '../../helpers/scrollToTop'

import ArrowUpIcon from '../Icon/ArrowUpIcon'

interface BackTopContent {
  title?: string
}

export const BackTop: FC<BackTopContent> = ({ title = 'common.backTop' }) => {
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const updateVisible = () => {
    const root = getLayoutRoot()
    if (root) {
      setIsVisible(root.clientHeight < root.scrollHeight)
    }
  }

  useEffect(() => {
    if (!ref.current) {
      return () => {}
    }
    const content = ref.current.closest('.alf-content') || ref.current
    const resizeObserver = new ResizeObserver(updateVisible)
    resizeObserver.observe(content)
    return () => resizeObserver.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="back-top" ref={ref}>
      {isVisible && (
        <div className="back-top-button" onClick={scrollToTop}>
          <div className="back-top-button-content">
            <ArrowUpIcon />
          </div>
          {title}
        </div>
      )}
    </div>
  )
}

export default BackTop

import { memo, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import debounce from 'lodash/debounce'
import { useRouteQuery } from '../../hooks'
import { SearchInput } from '../SearchInput/SearchInput'

interface SearchBarProps {
  placeholder?: string
  setStringFilter?: (value: string) => void
  children?: ReactElement<any>
}

export const SearchBar = memo<SearchBarProps>(
  ({ placeholder, setStringFilter, children }) => {
    const { t } = useTranslation()
    const history = useHistory()

    // Get the current search query from the URL
    const value = useRouteQuery().search

    // Function to update the URL with the search query
    const searchFunction = useCallback(
      (str: string) => {
        const params = new URLSearchParams(window.location.search)

        if (str) {
          params.set('search', str)
        } else {
          // Clear search-related query parameters
          ;['search', 'from', 'to'].forEach(name => params.delete(name))
        }

        // Update the URL without triggering a page reload
        history.replace({
          ...history.location,
          search: params.toString(),
        })
      },
      [history]
    )

    // Debounced onChange handler for the search input
    const onChange = useCallback(
      (value: string | number) =>
        debounce(setStringFilter ?? searchFunction, 500)(value.toString()),
      [setStringFilter, searchFunction]
    )

    return (
      <SearchInput
        compact
        placeholder={placeholder ?? t('common.searchInputPlaceholder')}
        value={value}
        onChange={onChange}
      >
        {value ? children : undefined}
      </SearchInput>
    )
  }
)

SearchBar.displayName = 'SearchBar' // Set display name for debugging purposes

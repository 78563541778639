const isBuffer = obj => obj && obj.constructor && typeof obj.constructor.isBuffer === 'function' && obj.constructor.isBuffer(obj);
const keyIdentity = key => key;
const returnFalse = () => false;
const returnTrue = () => true;
exports.flatten = (target, opts) => {
  opts = opts || {};
  const delimiter = opts.delimiter || '.';
  const maxDepth = opts.maxDepth;
  const transformKey = opts.transformKey || keyIdentity;
  const preventFlatten = opts.preventFlatten || returnFalse;
  const filter = opts.filter || returnTrue;
  const output = {};
  function step(object, prev, currentDepth) {
    currentDepth = currentDepth || 1;
    // eslint-disable-next-line consistent-return
    Object.keys(object).forEach(function (key) {
      const value = object[key];
      const isarray = opts.safe && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isbuffer = isBuffer(value);
      const isobject = type === '[object Object]' || type === '[object Array]';
      const newKey = prev ? prev + delimiter + transformKey(key) : transformKey(key);
      if (!preventFlatten(value) && !isarray && !isbuffer && isobject && Object.keys(value).length && (!opts.maxDepth || currentDepth < maxDepth)) {
        return step(value, newKey, currentDepth + 1);
      }
      if (filter(value, key, object)) {
        output[newKey] = value;
      }
    });
  }
  step(target);
  return output;
};
export const ENTITY_TYPES = { COMPANY: 'company', PERSON: 'person' }
export const ENTITY_TYPES_PLURAL = {
  COMPANIES: 'companies',
  PERSONS: 'persons',
}
export const ENTITY_TYPE_FIELD_NAME = 'type'
export const ENTITY_FIELD_TYPE_NAME = 'entity'

export const CALENDAR_DEADLINE_TYPES = {
  ACCOUNTING: 'accounting',
  CONTRACTUAL: 'contractual',
  FISCAL: 'fiscal',
  LITIGATION: 'litigation',
  SOCIAL: 'social',
}

export const CALENDAR_DEFAULT_DEADLINES_TITLES = {
  accountsClosing: 'Cloture comptable',
  cfePaymentRegEx: /^paiement CFE (?<date>.+)$/,
  companyRegistrationRegEx: /^Enregistrement de (?<companyName>.+)$/,
  vatPaymentRegEx: /^paiement TVA (?<date>.+)$/,
}

import gql from 'graphql-tag'
import { userFragment } from '../../fragments/user'

export const getProcessInstanceStepEmailResolvedQuery = gql`
  query getProcessInstanceStepEmailResolved($id: ID!, $stepId: ID!) {
    getProcessInstanceStepEmailResolved(id: $id, stepId: $stepId)
  }
`

export const getProcessInstancesShortQuery = gql`
  query GetProcessInstances(
    $offset: Int
    $sortBy: ProcessInstanceSortBy
    $sortDirection: SortDirection
    $search: String
    $dossiersListType: String
    $from: String
    $to: String
    $limit: Int
  ) {
    getProcessInstances(
      offset: $offset
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      dossiersListType: $dossiersListType
      from: $from
      to: $to
      limit: $limit
    ) {
      count
      edges {
        node {
          id
          name
          label
        }
      }
      totalCount
    }
  }
`

export const getProcessInstanceLogsQuery = gql`
  query getProcessInstanceLogs(
    $offset: Int!
    $limit: Int
    $logCategoryTypes: [Int]
    $processInstanceId: ID!
  ) {
    getProcessInstanceLogs(
      offset: $offset
      limit: $limit
      logCategoryTypes: $logCategoryTypes
      processInstanceId: $processInstanceId
    ) {
      count
      categoryCount
      processInstanceLogs {
        id
        createdAt
        applicationId
        processInstanceId
        metadata
        type
      }
    }
    getIncomingEmailsWithProcessInstanceId(
      processInstanceId: $processInstanceId
    ) {
      id
      to
      from
      date
      subject
      viewed
      flagged
      user {
        id
        email
        facebookId
        firstname
        lastname
        disabledAt
      }
      children {
        id
        to
        from
        date
        subject
        viewed
        flagged
        user {
          id
          email
          facebookId
          firstname
          lastname
          disabledAt
        }
      }
    }
  }
`

export const getProcessInstanceQuery = gql`
  ${userFragment}
  query getProcessInstance($id: ID!) {
    getProcessInstance(id: $id) {
      bcc
      product {
        process {
          label
        }
        id
        analyseId
        timeTracker
      }
      dataResolved
      documents {
        id
        user {
          ...userFragment
        }
        metadata
        languages
        mimetype
        filename
        filetype {
          id
          title
        }
        templateId
        url
        createdAt
        updatedAt
        flags
        tags
        isLatest
        isDraft
        hasHtml
        currentVersion
        versions {
          version
          createdAt
          isEdited
        }
        status
        size
      }
      id
      label
      name
      status
      user {
        id
      }
      workflow {
        config
        configResolved
        id
        name
        dependsOnPreviousSteps
        description
        status
        type
        toRevalidate
        requiresValidation
        restrictedAccess
        useFinancialData
        financialField
        access {
          read
          write
        }
      }
      timeSpent
      userTimer {
        id
        createdAt
        note
      }
      users {
        ...userFragment
      }
    }
  }
`

export const getProcessInstancesQuery = gql`
  query GetProcessInstances(
    $offset: Int
    $sortBy: ProcessInstanceSortBy
    $sortDirection: SortDirection
    $search: String
    $dossiersListType: String
    $from: String
    $to: String
    $limit: Int
  ) {
    getProcessInstances(
      offset: $offset
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      from: $from
      to: $to
      dossiersListType: $dossiersListType
      limit: $limit
    ) {
      count
      edges {
        node {
          bcc
          id
          label
          archivedAt
          createdAt
          product {
            id
            title
            category {
              color
            }
            icon {
              id
              url
            }
          }
          startedAt
          status
          user {
            id
            email
            facebookId
            firstname
            lastname
            disabledAt
          }
          users {
            id
            email
            facebookId
            firstname
            lastname
            disabledAt
          }
          workflow {
            id
            name
            status
            type
            toDo
            toRevalidate
          }
          deletedAt
        }
      }
      totalCount
    }
  }
`

const pick = require('lodash/pick');
const {
  definitions
} = require('./definitions');

//TODO Find alternative to eval or Protect eval call
//TODO Add memo function (from lodash ?)
const createDef = (def = {}) => Object.keys(def).reduce((acc, field) => ({
  ...acc,
  [field]: eval(def[field])
}), {});
exports.getModel = ({
  type,
  ...props
}, loop = 0) => {
  const definition = definitions[type];
  if (!definition) {
    return undefined;
  }
  const {
    inherit,
    ...addProps
  } = definition;
  if (type === inherit) {
    throw Error(`Cannot inherit from same type: ${type}`);
  }
  if (3 < loop) {
    throw Error(`Reach maximum number of inheritance`);
  }
  return {
    ...(inherit && exports.getModel({
      type: inherit
    }, loop + 1)),
    ...addProps,
    ...createDef(pick(props, ['fake']))
  };
};
exports.getType = ({
  type
}) => {
  function getTypeRecurs(type) {
    const {
      inherit
    } = definitions[type] || {};
    return inherit ? getTypeRecurs(inherit) : type;
  }
  return getTypeRecurs(type);
};
exports.getSerializer = (type, additional, {
  throwError = true
} = {}) => {
  const typeObject = {
    ...additional,
    type
  };
  const {
    serialize,
    dataOptions
  } = exports.getModel(typeObject) || {};
  if (!serialize) {
    if (throwError) {
      throw Error(`Can't find serialize for type : ${type}`);
    }
    return null;
  }
  return serialize({
    ...dataOptions,
    ...typeObject
  });
};
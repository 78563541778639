const {
  convertToFloat
} = require('../helpers/utils');

// Change local to display FR as DE wich mean 123.456,789 format
const getLocale = language => language = 'fr' ? 'de' : language;
exports.money = {
  serialize: ({
    type,
    currency = 'EUR',
    language
  }) => (value = '0') => {
    const locale = getLocale(language);
    const {
      num,
      isWrittingDecimal,
      decimal
    } = convertToFloat((value ?? '0').toString(), locale);
    return {
      value: num,
      isWrittingDecimal,
      decimal,
      type,
      currency,
      locale
    };
  },
  deserialize: ({
    value,
    isWrittingDecimal,
    decimal,
    language = 'fr'
  } = {}) => {
    const locale = getLocale(language);
    const val = new Intl.NumberFormat(locale).format(value);
    const finalValue = isWrittingDecimal ? `${val}${decimal}` : val;
    return /^[\ ,.0-9]*$/.test(finalValue) ? finalValue : '';
  },
  toStr: ({
    value = 0,
    currency = 'EUR',
    language = 'fr'
  } = {}, {
    minDigits = 0,
    maxDigits = 2
  } = {}) => {
    const locale = getLocale(language);
    return isNaN(value) ? value : new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits
    }).format(value);
  }
};
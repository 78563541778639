import {
  CONTENT_TYPE_SYNCFUSION_DOCUMENT_TEXT,
  DOCUMENT_STATUS,
  WORD_DOCUMENT_TYPES,
} from '../constants'
import { getUrlConfig } from './getUrlConfig'
import { triggerDownloadLink } from './download'

export interface Document {
  mimetype: string
  id: string
  currentVersion: number
  url: string
}

export const documentIsNotValided = (documentStatus: number) =>
  documentStatus === DOCUMENT_STATUS.REJECTED ||
  documentStatus === DOCUMENT_STATUS.TO_VALIDATE

export const downloadDocument = (
  document: Document,
  language = 'fr',
  slug: string
) =>
  triggerDownloadLink(
    getPreviewLink(document, slug) + '/download?lang=' + language.toUpperCase()
  )

export const getExtension = (str: string) =>
  str.includes('.') ? '.' + str.split('.').slice(-1)[0] : ''

export const removeExtension = (str: string) =>
  str.includes('.') ? str.split('.').slice(0, -1).join('.') : str

export const getPreviewLink = (document: Document, slug: string): string =>
  `${getUrlConfig(slug).APP_URL}${document.url}`

export const getDownloadUrl = (
  document: Document,
  language = 'fr',
  slug: string
) => {
  const { mimetype, id, currentVersion } = document
  const version = id.split('__')?.[1] || currentVersion
  return [
    CONTENT_TYPE_SYNCFUSION_DOCUMENT_TEXT,
    ...WORD_DOCUMENT_TYPES,
  ].includes(mimetype)
    ? 'document/' + id.split('__')[0] + '/version/' + version
    : `${getUrlConfig(slug).APP_URL}${document.url}?lang=${language.toUpperCase()}`
}

export const DataURIToBlob = (dataURI: string) => {
  const splitDataURI = dataURI.split(',')
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

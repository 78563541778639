import gql from 'graphql-tag'

export const getUserPasswordTokenQuery = gql`
  query GetUserPasswordToken($id: ID!) {
    getUserPasswordToken(id: $id) {
      id
      expired
    }
  }
`

export const getUserSlugsQuery = gql`
  query GetUserApplications($email: String!, $password: String!) {
    getUserApplications(email: $email, password: $password) {
      slug
      logoUrl
    }
  }
`

export const checkIfUserEmailExistQuery = gql`
  query checkIfUserEmailExist($email: String!) {
    checkIfUserEmailExist(email: $email)
  }
`

export const getCurrentUserQuery = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      createdAt
      firstname
      lastname
      email
      welcomedAt
      facebookId
      incomingMail
      phone
      post
      role {
        id
        title
        features
        isAdmin
      }
      groups {
        id
        color
        name
        userCount
      }
    }
  }
`

import React from 'react';

interface HandleFieldWithoutPrefixParams {
  prefix?: string;
  filename?: string;
  [key: string]: any;
}

export const useProcessedFilenames = (
  params: HandleFieldWithoutPrefixParams
): Record<string, string> => {
  return React.useMemo(() => {
    const filenameMap: Record<string, string> = {};
    const regex = /^filename_/;
    
    // Convert language suffix to uppercase (filename_en -> EN)
    const getLanguageCode = (key: string) => key.split('_')[1].toUpperCase();

    if (params.prefix) {
      const prefixWithDot = `${params.prefix}.`;
      const prefixEndIndex = params.prefix.lastIndexOf('[');
      const prefixWithOutIndex = prefixEndIndex > 0 
        ? `${params.prefix.slice(0, prefixEndIndex)}.`
        : `${params.prefix}.`;

      for (const key of Object.keys(params)) {
        if (regex.test(key)) {
          const value = params[key];
          if (!value) {continue;}

          const langCode = getLanguageCode(key);
          filenameMap[langCode] = value.includes(prefixWithDot)
            ? value
            : value.replaceAll(prefixWithOutIndex, prefixWithDot);
        }
      }
    } else {
      for (const key of Object.keys(params)) {
        if (regex.test(key)) {
          filenameMap[getLanguageCode(key)] = params[key];
        }
      }
    }

    return Object.keys(filenameMap).length > 0 
      ? filenameMap 
      : { EN: params.filename || '' };
  }, [params]); // Only recompute when params change
};
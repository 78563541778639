import { useState, useEffect, useCallback } from 'react';
import { Modal } from 'antd';
const checkType = type => {
  if (!['info', 'success', 'error', 'warning', 'confirm'].includes(type)) {
    throw Error(`Type '${type}' is not handle by ant design modal`);
  }
};
export const useModal = ({
  content: initialContent = undefined,
  visible = false,
  type = 'info',
  ...props
} = {}) => {
  const [modalParams] = useState({
    visible,
    ...props
  });
  useEffect(() => {
    checkType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const displayModal = useCallback(({
    type: newType,
    ...params
  }) => {
    if (Boolean(Object.keys(props))) {
      checkType(newType);
      Modal[newType](params);
    } else {
      Modal[type]({
        ...modalParams,
        visible: true
      });
    }
  }, [modalParams, props, type]);
  return {
    displayModal
  };
};
export const userFragment = `
  fragment userFragment on User {
    firstname
    lastname
    email
    id
    role {
      title
      isAdmin
    }
    post
    groups {
      name
    }
    sentAt
  }
`

const { faker } = require('@faker-js/faker')

exports.multipleSelect = {
  serialize:
    ({ type, options }) =>
    value => ({
      value:
        (value && Array.isArray(value.value) && value.value) ||
        (Array.isArray(value) && value) ||
        [],
      type,
      options,
    }),
  deserialize: ({ value } = {}) => value,
  validate:
    () =>
    ({ value = [], options = [] } = {}) =>
      value.every(
        v => options.findIndex(option => option.value || option === v) >= 0
      )
        ? null
        : 'requiredField',
  fake: faker.random.arrayElements,
}

import { PROCESS_INSTANCE_LOG_TYPE } from '../constants'

// Define the type based on the keys of `PROCESS_INSTANCE_LOG_TYPE`
type ProcessInstanceLogType = keyof typeof PROCESS_INSTANCE_LOG_TYPE

// Function to get process instance type by code
export const getProcessInstanceTypeByCode = (
  code: number
): ProcessInstanceLogType | 'generic' => {
  // Find the key corresponding to the provided code
  const keys = Object.keys(
    PROCESS_INSTANCE_LOG_TYPE
  ) as ProcessInstanceLogType[]
  const value = keys.find(key => PROCESS_INSTANCE_LOG_TYPE[key] === code)

  // If a matching key is found, return the key, otherwise return 'generic'
  return value ?? 'generic'
}

import { FC, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import Happy from '../Icon/ValidIcon'
import Sad from '../Icon/NotValidIcon'
import { AuthorizationError } from './AuthorizationField'
import { Tooltip } from '../Tooltip/Tooltip'

interface RenderErrorProps {
  error: AuthorizationError
  disableValidateStyle: boolean
  value: string
  disableTooltip: boolean
  inputFocus: boolean
}

export const RenderError: FC<RenderErrorProps> = ({
  error,
  disableValidateStyle,
  value,
  disableTooltip,
  inputFocus,
}) => {
  const hidden = useMemo(
    () => disableValidateStyle || !value || inputFocus,
    [disableValidateStyle, value, inputFocus]
  )

  const Component = useCallback(() => {
    if (error?.submitError) {
      return error?.textSubmitError ? (
        <Tooltip title={error.textSubmitError} visible={true}>
          <div>
            <Sad />
          </div>
        </Tooltip>
      ) : (
        <></>
      )
    }
    if (!error?.validationError) {
      return (
        <Tooltip
          title={error?.textValidateError}
          visible={hidden ? false : true}
        >
          <div>
            <Sad />
          </div>
        </Tooltip>
      )
    }
    return <Happy />
  }, [error, disableTooltip, hidden])

  return (
    <div
      className={classNames(
        'sign-in-form-info',
        hidden && 'sign-in-form-info--hidden'
      )}
    >
      <Component />
    </div>
  )
}

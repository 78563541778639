exports.selectAsyncData = {
  serialize:
    ({ type, asyncData }) =>
    value => ({
      value: value?.value || value || undefined,
      type,
      asyncData,
    }),
  deserialize: ({ value } = {}) => value,
  validate:
    () =>
    ({ value } = {}) =>
      value ? null : 'requiredField',
  // fake: faker.random.arrayElement,
  toStr: (
    { value, options = [] } = {},
    { locale = 'en', fieldName = 'label' } = {}
  ) => {
    const getter = (obj, field) =>
      obj[`${field}_${locale.toLowerCase()}`] || obj[field]

    if (Array.isArray(value)) {
      const res = options.filter(option => value.includes(option.value)) || []
      return res
        .map(item => getter(item, fieldName) || getter(item, 'label') || item)
        .join(', ')
    }
    const res =
      options.find(
        option => (option.value !== undefined ? option.value : option) === value
      ) || {}
    return getter(res, fieldName) || getter(res, 'label') || ''
  },
}

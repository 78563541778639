import gql from 'graphql-tag'
import { userFragment } from '../../fragments/user'
export const getEntityAndTypesAndCateogiresQuery = gql`
  ${userFragment}
  query GetEntityAndUser($idEntity: ID!) {
    getEntity(id: $idEntity) {
      id
      dataResolved
      isPublic
      documents {
        id
      }
      type {
        id
        typeName
        typeNamePlural
        creationForm
      }
      primaryContact
      name
      userId
      users {
        ...userFragment
      }
      processInstances {
        id
        dataResolved
        name
        status
        createdAt
        label
        product {
          id
          title
          category {
            color
          }
          icon {
            id
            url
          }
        }
        workflow {
          config
          configResolved
          id
          name
          dependsOnPreviousSteps
          description
          status
          type
          toRevalidate
          requiresValidation
          restrictedAccess
          useFinancialData
          financialField
        }
      }
      summaryColabsBuilder {
        id
        workflow {
          jobId
          config
          type
        }
      }
    }
    getEntityTypes {
      id
      typeNamePlural
      icon
    }
    getEntityCategories {
      id
      categoryName
      type {
        id
        typeName
        creationForm
      }
    }
  }
`

export const getEntityQuery = gql`
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      id
      name
      userId
      dataResolved
      type {
        id
        entityNamePath
        creationForm
        title
      }
    }
  }
`

export const getEntityTypesQuery = gql`
  query GetEntityTypes {
    getEntityTypes {
      id
      title
      icon
      position
      entityNamePath
      creationForm
      typeName
      typeNamePlural
      count
      createdAt
      updatedAt
    }
  }
`

export const getEntityCategoriesQuery = gql`
  query GetEntityCategories {
    getEntityCategories {
      id
      type {
        id
        typeName
        typeNamePlural
        title
        icon
        position
        creationForm
      }
      categoryName
      entityNamePath
      title
      icon
      entityCount
      createdAt
      updatedAt
    }
  }
`

export const getEntitiesByCategoryNameQuery = gql`
  query GetEntitiesByCategoryName($categoryName: [String!]) {
    getEntitiesByCategoryName(categoryName: $categoryName, addPublic: true) {
      id
      name
      data
      category {
        id
        categoryName
      }
      type {
        id
        title
        typeName
        icon
      }
    }
  }
`

import { gql } from 'graphql-tag'
import { applicationFragment } from '../../fragments'

export const updateApplicationMutation = gql`
  ${applicationFragment}
  mutation ($id: ID!, $input: ApplicationInput!) {
    updateApplication(id: $id, input: $input) {
      ...applicationFragment
    }
  }
`

export const createApplicationMutation = gql`
  mutation CreateApplication($input: ApplicationInput!) {
    createApplication(input: $input) {
      id
      logoUrl
    }
  }
`

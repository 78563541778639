import './NavigationArrows.less'
import InnerButton from '../InnerButton/InnerButton'
import ChevronLeftIcon from '../Icon/ChevronLeftIcon'
import ChevronRightIcon from '../Icon/ChevronRightIcon'

interface NavigationArrowsProps {
  leftDisabled?: boolean
  rightDisabled?: boolean
  onLeftClick?: () => void
  onRightClick?: () => void
  extra?: React.ReactNode
}

export const NavigationArrows: React.FC<NavigationArrowsProps> = ({
  leftDisabled,
  rightDisabled,
  onLeftClick,
  onRightClick,
  extra,
}) => {
  return (
    <div className="navigation-arrows">
      <InnerButton
        disabled={leftDisabled}
        className="navigation-arrows-arrow"
        onClick={onLeftClick}
        icon={<ChevronLeftIcon />}
      />
      {extra}
      <InnerButton
        disabled={rightDisabled}
        className="navigation-arrows-arrow"
        onClick={onRightClick}
        icon={<ChevronRightIcon />}
      />
    </div>
  )
}

import { useMemo } from 'react'
import { useLocation } from './useLocation'

/**
 * A custom hook to parse and retrieve query parameters from the current URL.
 * @returns An object containing key-value pairs of the query parameters.
 */
export const useRouteQuery = (): Record<string, string> => {
  const { search } = useLocation()

  return useMemo(() => {
    // Create a URLSearchParams object from the search string
    const searchParams = new URLSearchParams(search)

    // Convert URLSearchParams entries into an object
    return Array.from(searchParams.entries()).reduce<Record<string, string>>(
      (result, [key, value]) => {
        result[key] = value
        return result
      },
      {}
    )
  }, [search])
}

import jmespath from 'jmespath'
import { flattenDeep } from 'lodash'
import { getDataValue } from '../helpers/utils/getDataValue'

export interface Condition {
  key: string | string[]
  op: string
  value: any | any[]
  keyPath?: string
  condition?: Condition
}

interface SanitizedCondition {
  condition?: Condition
  key: string
  keyPath?: string
  op: string
  value: any
}

/**
 * Sanitizes a condition object by ensuring all properties are singular values (not arrays).
 * If any property is an array, the first element of the array is used.
 *
 * @param {Condition} condition - The condition object to sanitize.
 * @returns {SanitizedCondition} - The sanitized condition object.
 */
const sanitizeCondition = ({
  key,
  op,
  value,
  keyPath,
  condition,
}: Condition): SanitizedCondition => {
  if (Array.isArray(key)) {
    return {
      condition,
      key: key[0],
      keyPath: keyPath?.[0],
      op: op[0],
      value: value[0],
    }
  }

  return { condition, key, keyPath, op, value }
}

/**
 * Retrieves a value from a nested object using a dot-separated key path.
 * @param state - The state object to search.
 * @param keypath - The dot-separated key path.
 * @returns The value at the specified key path, or null if not found.
 */
const getPathValue = (state: any, keypath: string): any => {
  try {
    const keys = keypath.split('.')
    return keys.reduce((previous, current) => previous?.[current], state)
  } catch {
    return null
  }
}

/**
 * Checks if a condition is fulfilled based on the provided state.
 * @param condition - The condition to evaluate.
 * @param state - The state object to evaluate against.
 * @returns Whether the condition is fulfilled.
 */
export const conditionIsFullfilled = (
  condition: Condition,
  state: any
): boolean => {
  const checkValue = (
    value: any,
    exceptedValue: any,
    op: string,
    subCondition: Condition[]
  ): boolean => {
    switch (op) {
      case 'eq':
      case 'ne': {
        const result = Array.isArray(value)
          ? JSON.stringify(value.sort()) ===
            JSON.stringify(exceptedValue.sort())
          : value === exceptedValue
        return op === 'ne' ? !result : result
      }
      case 'inc':
      case 'ninc': {
        const result = (value || []).includes(exceptedValue)
        return op === 'ninc' ? !result : result
      }
      case 'lt':
        return value > exceptedValue
      case 'le':
        return value >= exceptedValue
      case 'ge':
        return value <= exceptedValue
      case 'gt':
        return value < exceptedValue
      case 'jump':
        return true
      case 'leq':
        return (exceptedValue || []).includes(value)
      case 'lne':
        return !(exceptedValue || []).includes(value)
      case 'and':
        if (!subCondition.length) {
          return true
        }
        return subCondition.every(cond => conditionIsFullfilled(cond, state))
      case 'or':
        if (!subCondition.length) {
          return true
        }
        return subCondition.some(cond => conditionIsFullfilled(cond, state))
      case 'not':
        return !subCondition.every(cond => conditionIsFullfilled(cond, state))
      case 'isSet':
      case 'isNotSet': {
        const isSet = exceptedValue !== null && exceptedValue !== undefined
        return op === 'isSet' ? isSet : !isSet
      }
      default:
        return true
    }
  }

  if (!condition) {
    return true
  }

  const {
    key,
    op,
    value,
    keyPath,
    condition: subCondition = [],
  } = sanitizeCondition(condition)

  const exceptedValue = getDataValue(
    keyPath ? getPathValue(state, keyPath) : key && jmespath.search(state, key)
  )

  if (Array.isArray(exceptedValue)) {
    return flattenDeep(exceptedValue).some(item =>
      checkValue(
        value,
        getDataValue(item),
        op,
        Array.isArray(subCondition) ? subCondition : [subCondition]
      )
    )
  }
  return checkValue(
    value,
    exceptedValue,
    op,
    Array.isArray(subCondition) ? subCondition : [subCondition]
  )
}

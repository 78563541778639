import { useMemo } from 'react'
import { PermissionHeaderProps } from '../../PermissionTable.d'
import { PermissionRoleHeader } from './PermissionRoleHeader'

export const PermissionHeader = ({
  roles,
  headerEditable,
  editable,
  isEditingTitle,
  deletable,
  lang,
  setIsEditingTitle,
  setRoles,
  title,
}: PermissionHeaderProps) => {
  const RolesHeader = useMemo(() => {
    return roles.map((role, i) => (
      <PermissionRoleHeader
        key={`title-${i}`}
        role={role}
        index={i}
        lang={lang}
        headerEditable={headerEditable}
        editable={editable}
        deletable={role.sortOrder !== 0 && role.count === 0 && deletable}
        isEditingTitle={isEditingTitle}
        setIsEditingTitle={setIsEditingTitle}
        roles={roles}
        setRoles={setRoles}
      />
    ))
  }, [roles, lang, isEditingTitle, editable, setRoles])

  return (
    <div className="permission-row title-row">
      <div className="permission-column">
        {title && <div className="permission-title">{title}</div>}
      </div>
      <div className="role-columns">{RolesHeader}</div>
    </div>
  )
}

import gql from 'graphql-tag'
import { applicationFragment } from '../../fragments'

export const getCurrentApplicationQuery = gql`
  ${applicationFragment}
  query GetCurrentApplication {
    getCurrentApplication {
      ...applicationFragment
    }
  }
`

export const getApplicationQuery = gql`
  ${applicationFragment}
  query getApplication($id: ID!) {
    getApplication(id: $id) {
      ...applicationFragment
    }
  }
`

export const getApplicationOpenIdConnectorsQuery = gql`
  query getApplicationOpenIdConnectors {
    getApplicationOpenIdConnectors
  }
`

import { gql } from 'graphql-tag'

export const applicationFragment = gql`
  fragment applicationFragment on Application {
    emailTemplates
    currentActiveAdmins
    currentActiveUsers
    currentWorkflows
    currentServices
    currentDossiers
    currentPendingAdminInvitations
    currentPendingInvitations
    maxAdmins
    maxUsers
    maxWorkflows
    maxDossiers
    docuSignBcc
    emailCc
    emailCcEnabled
    id
    languages
    logoUrl
    faqTitle
    defaultRole {
      id
      title
    }
    plan {
      id
      type
      name
    }
    logoLinkUrl
    name
    branding
    public
    slug
    theme
    termsAndConditions
    termsConditionsUrl
    termsConditionsUrlEnabled
    welcomeContent
    welcomeEnabled
    welcomeLocation
    displayServiceCatalog
    company {
      address
      capital
      companyName
      country
      city
      legalForm
      rcsCity
      rcsNumber
      siret
      vatNumber
      zipCode
    }
    roles
    legacySubscriptions
    legalRepresentative {
      type
      firstName
      lastName
      dateOfBirth
      cityOfBirth
      zipCodeOfBirth
      address
      zipCode
      city
      cityResidence
      zipCodeResidence
      nationality
      companyName
      legalForm
      capital
      cityLegal
      zipCodeLegal
      rcsNumber
      rcsCity
      adressHeadquarter
      dateOpeningFiscal
      dateClosingFiscal
    }
    superAdminRole {
      id
      title
    }
    deletedAt
  }
`

export interface Base64TagResult {
  b64: string | ArrayBuffer | null
  width: number
  height: number
}

export const getBase64Tag = (
  html: string,
  type: 'variable' | 'condition' | 'sign' | 'loop' = 'variable'
): Promise<Base64TagResult> => {
  const styles: Record<string, [string, string]> = {
    condition: ['#435AD8', '#435AD820'],
    variable: ['#27AE60', '#27AE6020'],
    sign: ['#AE27A1', '#AE27A120'],
    loop: ['#AE7B27', '#AE7B2720'],
  }

  return new Promise(resolve => {
    const wrapper = document.createElement('div')
    wrapper.style.display = 'block'
    wrapper.style.width = 'fit-content'
    wrapper.style.borderRadius = '3px'
    wrapper.style.padding = '0 3px'
    wrapper.className = `alf alf-${type}`
    wrapper.style.color = styles[type][0]
    wrapper.style.background = styles[type][1]
    wrapper.innerHTML = html

    document.body.appendChild(wrapper)
    const width = wrapper.offsetWidth
    const height = wrapper.offsetHeight

    const canvas = document.createElement('canvas')
    canvas.width = height
    canvas.height = width
    const ctx = canvas.getContext('2d')

    const data = `
      <svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}'>
        <foreignObject width='100%' height='100%'>
          <div xmlns='http://www.w3.org/1999/xhtml'>${wrapper.outerHTML}</div>
        </foreignObject>
      </svg>
    `

    document.body.removeChild(wrapper)

    const img = new Image()
    const svg = new Blob([data], { type: 'image/svg+xml;charset=utf-8' })
    const url = URL.createObjectURL(svg)

    img.onload = function () {
      if (!ctx) return
      ctx.drawImage(img, 0, 0)
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        const reader = new FileReader()
        reader.onloadend = function () {
          resolve({ b64: reader.result, width, height })
          URL.revokeObjectURL(url)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    }

    img.crossOrigin = 'Anonymous'
    img.src = url
  })
}

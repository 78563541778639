export const FEATURES = {
  aiChatCreate: 'ai.chat.create',
  aiDocumentDataExtraction: 'ai.document.dataextraction',
  calendarSee: 'calendar.see',
  calendarSubscribe: 'calendar.subscribe',
  clauseCreate: 'clause.create',
  clauseDelete: 'clause.delete',
  clauseSee: 'clause.see',
  clauseUpdate: 'clause.update',
  dossierArchive: 'dossier.archive',
  dossierCreate: 'dossier.create',
  dossierDelete: 'dossier.delete',
  dossierRestore: 'dossier.restore',
  dossierSeeAll: 'dossier.see.all',
  dossierShare: 'dossier.share',
  dossierUpdate: 'dossier.update',
  dossierDuplicate: 'dossier.duplicate',
  email: 'email',
  entityCreate: 'entity.create',
  entityDelete: 'entity.delete',
  entitySeeAll: 'entity.see.all',
  entityShare: 'entity.share',
  entityTypeCreate: 'entity.type.create',
  entityTypeDelete: 'entity.type.delete',
  entityTypeManage: 'entity.type.manage',
  entityTypeSee: 'entity.type.see',
  entityTypeUpdate: 'entity.type.update',
  entityUpdate: 'entity.update',
  platformApisManage: 'platform.apis.manage',
  platformManage: 'platform.manage',
  platformSetupManage: 'platform.setup.manage',
  portfolioSee: 'portfolio.see',
  portfolioUpdate: 'portfolio.update',
  profileSee: 'profile.see',
  profileUpdate: 'profile.update',
  reportCreate: 'report.create',
  reportDelete: 'report.delete',
  reportShare: 'report.share',
  reportSeeAll: 'report.see.all',
  reportUpdate: 'report.update',
  roleCreate: 'role.create',
  roleDelete: 'role.delete',
  roleUpdate: 'role.update',
  serviceCategoryCreate: 'service.category.create',
  serviceCategoryDelete: 'service.category.delete',
  serviceCategoryUpdate: 'service.category.update',
  serviceCreate: 'service.create',
  serviceDelete: 'service.delete',
  serviceExport: 'service.export',
  serviceSeeDraft: 'service.see.draft',
  serviceSeePublish: 'service.see.publish',
  serviceUpdate: 'service.update',
  stickynoteManage: 'stickynote.manage',
  stickynoteSeeAll: 'stickynote.see.all',
  subscriptionSee: 'subscription.see',
  superadmin: 'superadmin',
  supportContactEmail: 'support.contact.email',
  supportContactPhone: 'support.contact.phone',
  supportContentCreate: 'support.content.create',
  supportContentSee: 'support.content.see',
  supportContentUpdate: 'support.content.update',
  supportFaqsCreate: 'support.faqs.create',
  supportFaqsDelete: 'support.faqs.delete',
  supportFaqsSee: 'support.faqs.see',
  supportFaqsUpdate: 'support.faqs.update',
  teamManage: 'team.manage',
  teamSee: 'team.see',
  teamUserManage: 'team.user.manage',
  termsSee: 'terms.see',
  termsUpdate: 'terms.update',
  userInvite: 'user.invite',
  userManage: 'user.manage',
  userManageInvitation: 'user.manage.invitation',
  userSeeAll: 'user.see.all',
  welcomeSee: 'welcome.see',
  workflowManage: 'workflow.manage',
}

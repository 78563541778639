module.exports = {
  ...require('./number'),
  ...require('./text'),
  ...require('./boolean'),
  ...require('./select'),
  ...require('./datetime'),
  ...require('./selectAsyncData'),
  ...require('./file'),
  ...require('./idCheck'),
  ...require('./multipleSelect'),
  ...require('./uuid'),
  ...require('./money'),
  ...require('./entity')
};
const { PENDING, NOT_STARTED, DONE, ERROR } = require('./workflowProcessStep')

const status = [ERROR, PENDING, NOT_STARTED]

module.exports = workflow => {
  let result = -1 // Default to -1 if no valid step is found

  // Helper function to find the first or last index of a step with a given status
  const find = (name, which = '') =>
    workflow['find' + which + 'Index'](({ status }) => status === name)

  // Iterate through statuses in order of priority (ERROR, PENDING, NOT_STARTED)
  for (let index = 0; index < status.length; index++) {
    result = find(status[index])
    if (result >= 0) {
      return result // If a match is found, return immediately
    }
  }

  // If no match, find the last DONE step
  const done = find(DONE, 'Last')
  if (done >= 0) {
    const next = done + 1 // Compute the next step index
    if (next < workflow.length) {
      return next // Return the next step if it's within bounds
    }
    return done // Otherwise, return the last DONE step
  }

  return result // Default fallback if no valid step is found
}

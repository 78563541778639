import gql from 'graphql-tag'

export const signoutUserMutation = gql`
  mutation SignoutUser {
    signoutUser {
      id
    }
  }
`

export const recoverPasswordMutation = gql`
  mutation RecoverPassword($input: UserRecoverPasswordInput!) {
    recoverPassword(input: $input) {
      id
    }
  }
`

export const signupUserMutation = gql`
  mutation signupUser($input: UserSignupInput!) {
    signupUser(input: $input) {
      user {
        id
      }
      redirectURL
    }
  }
`

export const signinUserMutation = gql`
  mutation SigninUser($input: UserSigninInput!) {
    signinUser(input: $input) {
      user {
        id
      }
      redirectURL
    }
  }
`

export const signinByOpenIdConnectorMutation = gql`
  mutation signinByOpenIdConnector($id: ID!) {
    signinByOpenIdConnector(id: $id) {
      success
      message
      url
    }
  }
`

export const createUserPasswordTokenMutation = gql`
  mutation CreateUserPasswordToken($input: UserPasswordTokenInput!) {
    createUserPasswordToken(input: $input) {
      id
    }
  }
`

import { conditionIsFullfilled } from '../condition'

export interface Field {
  fields?: Field[]
  name: string
  deadlineName?: string
  label?: string
  type: string
  displayName?: string
  condition?: any[]
  [key: string]: any // For dynamic properties like deadlineName + languageType
}

export interface Form {
  type: string
  name: string
  fields?: Field[]
  config?: {
    fields?: Field[]
    name: string
    label?: string
    [key: string]: any // For dynamic properties like label + languageType
  }
}

interface WorkflowInfo {
  fieldsInfo: Record<string, any>
  framesInfo: Record<string, any>
}

export const getWorkflowInfo = (
  workflow: Form[] | Form,
  dataResolved: any,
  language: string
): WorkflowInfo => {
  const languageType =
    language.toLowerCase() === 'en' ? '' : '_' + language.toLowerCase()
  const labelName = 'label' + languageType

  const getFieldsInfo = (
    fields: Field[],
    frameName: string,
    path: string = ''
  ): Record<string, any> =>
    fields.reduce(
      (acc, field, fieldIndex) => {
        const {
          fields: subFields,
          name,
          deadlineName,
          label,
          type,
          displayName,
          condition,
          [labelName]: localizedLabel,
          ['deadlineName' + languageType]: deadlineLabel,
        } = field

        const fullPath = (path || '') + name

        if (
          (condition &&
            condition.length &&
            !path &&
            !condition.every(cond =>
              conditionIsFullfilled(cond, dataResolved)
            )) ||
          acc[fullPath]
        ) {
          return acc
        }

        const fieldInfo = {
          deadlineLabel: deadlineLabel || deadlineName,
          frameName,
          index: fieldIndex || deadlineName || 0,
          label: localizedLabel || label,
          type,
          displayName,
          ...(type === 'multiple' &&
            subFields && {
              ...getFieldsInfo(subFields, frameName, name + '.'),
            }),
          ...(path && {
            multiple: path,
          }),
        }

        return {
          ...acc,
          [fullPath]: fieldInfo,
        }
      },
      {} as Record<string, any>
    )

  const forms = (Array.isArray(workflow) ? workflow : [workflow])
    .filter(({ type }) => type === 'form')
    .flatMap(({ config }) => config || []) // Safely handle undefined config

  return {
    fieldsInfo: forms.reduce(
      (acc, formConfig) => {
        // Safely access `name` and `fields` with default values
        const { name = '', fields = [] } = formConfig || {}
        return {
          ...acc,
          ...getFieldsInfo(fields, name),
        }
      },
      {} as Record<string, any>
    ),
    framesInfo: forms.reduce(
      (acc, formConfig, index) => {
        // Safely access `name` and `label` with default values
        const {
          name = '',
          [labelName]: localizedLabel,
          label,
        } = formConfig || {}
        return {
          ...acc,
          [name]: {
            index,
            label: localizedLabel || label,
          },
        }
      },
      {} as Record<string, any>
    ),
  }
}

import React, { ReactNode, useMemo, forwardRef } from 'react'
import { ViewerHeader } from './ViewerHeader'
import { Arrow } from '../Arrow/Arrow'

interface ViewerWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  onPage?: (direction: number) => void
  pages?: number | null // Allow pages to be undefined or null
  page?: number | undefined
  shortPage?: boolean
  type?: string
  [key: string]: any // Allow additional props for flexibility
}

export const ViewerWrapper = forwardRef<HTMLDivElement, ViewerWrapperProps>(
  ({ children, onPage, pages, page, shortPage = false, ...props }, ref) => {
    const showNext = useMemo(
      () => pages && pages > 1 && page && page < pages,
      [pages, page]
    )
    const showPrevious = useMemo(
      () => pages && pages > 1 && page && page > 1,
      [pages, page]
    )
    const placeholder = shortPage && <div style={{ width: 50 }} />

    // Handle arrow clicks
    const handleArrowClick = (event: React.MouseEvent, direction: number) => {
      event.preventDefault()
      event.stopPropagation()
      onPage?.(direction) // Safely call onPage if defined
    }

    return (
      <div className="viewer-wrapper" ref={ref}>
        <ViewerHeader {...props} />
        <div className="viewer-wrapper-body">
          {typeof onPage === 'function' && showPrevious ? (
            <Arrow
              direction="left"
              isPage
              onClick={event => handleArrowClick(event, 0)} // Pass event and direction
            />
          ) : (
            placeholder
          )}
          {children}
          {typeof onPage === 'function' && showNext ? (
            <Arrow
              direction="right"
              isPage
              onClick={event => handleArrowClick(event, 1)} // Pass event and direction
            />
          ) : (
            placeholder
          )}
        </div>
      </div>
    )
  }
)

import { useState, useMemo, useEffect } from 'react'
import { isEqual } from 'lodash'
import { collectFrames } from '../helpers'

interface FrameType {
  id: string
  name: string
}

interface UseFormFramesReturn {
  currentFrame: FrameType | undefined
  previousFrame: FrameType | undefined
}

export const useFormFrames = (
  { config }: any,
  frameId: string,
  state: Record<string, any> = {}
): UseFormFramesReturn => {
  // Initialize state with frames based on the config and state
  const [frames, setFrames] = useState<FrameType[]>(
    collectFrames(config, state)
  )

  // Get the current frame based on frameId or the first frame
  const currentFrame = useMemo(() => {
    return frames.find(({ id }) => id === frameId) || frames[0]
  }, [frameId, frames])

  // Get the previous frame if currentFrame exists
  const previousFrame = useMemo(() => {
    if (currentFrame) {
      const currentIndex = frames.findIndex(({ id }) => id === currentFrame.id)
      return frames[currentIndex - 1]
    }
    return undefined
  }, [currentFrame, frames])

  // Update frames if the config or state changes
  useEffect(() => {
    const newFrames = collectFrames(config, state)
    if (!isEqual(newFrames, frames)) {
      setFrames(newFrames)
    }
  }, [config, frames, state])

  return { currentFrame, previousFrame }
}

// Define the expected structure of array items
interface ArrayItem {
  tlp?: { length: number }
}

// Finds the index where cumulative tlp length reaches or exceeds x
export const findIndexForValueX = (array: ArrayItem[], x: number): number => {
  if (!Array.isArray(array) || array.length === 0) {
    return -1
  }

  // Helper to calculate each item’s contribution to cumulative length
  const getLengthOrDefault = (item: ArrayItem): number =>
    item.tlp ? item.tlp.length : 1

  let cumulativeValue = 0

  for (let i = 0; i < array.length; i++) {
    cumulativeValue += getLengthOrDefault(array[i])
    if (cumulativeValue >= x) {
      return i // Return index as soon as cumulative meets/exceeds x
    }
  }

  // If x is not reached, return the last index instead of -1
  return array.length - 1
}

import React, { useMemo } from 'react'
import { Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import moment from 'moment'
import InnerButton, { ButtonType } from '../../InnerButton/InnerButton'
import { useRouteQuery } from '../../../hooks'
import Tag from '../../Tag/Tag'
import Settings from '../../Icon/Settings'

interface RangeProps {
  name: string
  parameter: { from?: string; to?: string; col?: string }
  onChange: (value: { from?: string; to?: string; col?: string }) => void
}

interface ColumnProps {
  text: string
  key: string
  parameter: { from?: string; to?: string; col?: string }
  onChange: (value: { from?: string; to?: string; col?: string }) => void
}

interface FilterProps {
  onChange: (value: { from?: string; to?: string; col?: string }) => void
  columns?: { text: string; key: string }[]
}

const option: Record<string, () => moment.Moment[]> = {
  today: () => [moment().startOf('day')],
  thisweek: () => [moment().startOf('week'), moment().endOf('week')],
  thismonth: () => [moment().startOf('month'), moment().endOf('month')],
  lastweek: () => [moment().subtract(1, 'week')],
  last2week: () => [moment().subtract(2, 'week')],
  lastmonth: () => [moment().subtract(1, 'month')],
  lastquarter: () => [moment().subtract(4, 'month')],
  lastyear: () => [moment().subtract(1, 'year')],
}

const date = 'YYYY-MM-DD'

const Range: React.FC<RangeProps> = ({ name, parameter, onChange }) => {
  const { t } = useTranslation()

  // Get the date range and ensure `to` defaults to the current moment
  const [from, to = moment()] = option[name]()

  // Format dates
  const formattedFrom = from.format(date)
  const formattedTo = to.format(date)

  // Check if the current range is selected
  const selected =
    parameter.from === formattedFrom && parameter.to === formattedTo

  return (
    <Tag
      type={selected ? 'selected' : 'base'}
      onClick={() =>
        onChange({
          ...(!selected && { from: formattedFrom, to: formattedTo }),
        })
      }
    >
      {t(`reportsPage.ranges.${name}`)}
    </Tag>
  )
}

const Column: React.FC<ColumnProps> = ({ text, key, parameter, onChange }) => {
  const resetValue = () => {
    const clonedValue = { ...parameter }
    delete clonedValue.col
    onChange(clonedValue)
  }

  const handleRangeChange = () => {
    if (selected) {
      resetValue()
    } else {
      onChange({ ...parameter, col: key })
    }
  }

  const selected = useMemo(() => parameter.col === key, [key, parameter])

  return (
    <Tag type={selected ? 'selected' : 'base'} onClick={handleRangeChange}>
      {text}
    </Tag>
  )
}

export const Filter: React.FC<FilterProps> = ({ onChange, columns = [] }) => {
  const parameter = useRouteQuery()

  return (
    <Popover
      showArrow={false}
      placement="bottom"
      trigger="click"
      overlayClassName="extra-filter-overlay"
      content={
        <div className="extra-filter-content">
          {Object.keys(option).map(name => (
            <Range
              key={name}
              {...{
                name,
                parameter,
                onChange,
              }}
            />
          ))}
          {columns.map(({ text, key }) => (
            <Column
              key={key}
              {...{
                text,
                parameter,
                onChange,
              }}
            />
          ))}
        </div>
      }
    >
      <InnerButton type={ButtonType.text}>
        <div
          className={classNames(
            'extra-filter-icon',
            (parameter.from || parameter.to || parameter.col) &&
              'extra-filter-icon--selected'
          )}
        >
          <Settings />
        </div>
      </InnerButton>
    </Popover>
  )
}

import { Suspense } from 'react'

import * as serviceWorker from './resources/serviceWorker'

import { lazy } from 'helpers/lazy'

import { Loader } from 'design-components'

const { pdfjs } = await import('react-pdf')

pdfjs.GlobalWorkerOptions.workerSrc = import(
  '../node_modules/react-pdf/dist/cjs/pdf.worker.entry'
)

import('styles/index.less')

import('./resources/i18n')

const GraphQlClient = lazy(
  () => import('./resources/GraphQlClient'),
  'GraphQlClient'
)
const ErrorBoundary = lazy(
  () => import('./resources/ErrorBoundary/ErrorBoundary'),
  'ErrorBoundary'
)
const Reloader = lazy(() => import('./resources/Reloader'), 'Reloader')
const BreakpointProvider = lazy(
  () => import('components/BreakpointContext'),
  'BreakpointProvider'
)
const AntDesign = lazy(() => import('./resources/AntDesign'), 'AntDesign')
const App = lazy(() => import('./App/App.jsx'), 'App')

export const Main = properties => (
  <Suspense fallback={<Loader />}>
    {[
      GraphQlClient,
      ErrorBoundary,
      Reloader,
      BreakpointProvider,
      AntDesign,
    ].reduceRight(
      (children, Component) => (
        <Component {...{ children }} />
      ),
      <App {...properties} />
    )}
  </Suspense>
)

serviceWorker.unregister()

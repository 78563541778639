exports.entity = {
  serialize:
    ({ type, entityTypes, prefixField = '' } = {}) =>
    ({ entityId, typeName, categoryId } = {}) => ({
      entityTypes,
      prefixField,
      type,
      value: {
        entityId,
        typeName,
        categoryId,
      },
      asyncData: 'entity',
    }),
  deserialize: ({ value } = {}) => {
    if (!value) {
      return {}
    }
    return {
      entityId: value.entityId,
      typeName: value.typeName,
      categoryId: value.categoryId,
    }
  },
  validate:
    () =>
    ({ value } = {}) =>
      value && (value.categoryId || value.entityId) ? null : 'requiredField',
}

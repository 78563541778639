import jmespath from 'jmespath'
import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-final-form'
import { parse } from 'mathjs'
import { replaceWildcardsWithPrefix } from '../helpers'

interface InputField {
  onChange: (value: any) => void
}

interface FormValues {
  [key: string]: any
}

const evaluateCalculation = (
  calculation: string,
  values: FormValues
): number | null => {
  if (!calculation) {
    return null
  }

  const expression = calculation.replace(/\$\{([^}]+)\}/g, (_, name) => {
    const sumValues = (val: any): number => {
      if (!Array.isArray(val)) {
        return 0 // If not an array, return 0
      }

      return val.reduce((acc: number, item: any) => {
        // Add the value if it exists and is a number
        const itemValue = typeof item.value === 'number' ? item.value : 0

        // If `item` has a nested array, recursively sum its values
        const nestedSum = Array.isArray(item) ? sumValues(item) : 0

        return acc + itemValue + nestedSum
      }, 0)
    }

    try {
      const val = jmespath.search(values, name) // Safely access nested/array values
      if (Array.isArray(val)) {
        return sumValues(val)
      }
      return val?.value || 0
    } catch (error) {
      console.error(error)
      return 0
    }
  })

  try {
    return parse(expression).evaluate()
  } catch (error) {
    console.error('Error evaluating calculation:', error)
    return null
  }
}

export const useReactiveCalculation = (
  input: InputField,
  calculation: string = '',
  prefix: string = ''
) => {
  const form = useForm()
  const prevValueRef = useRef<number | null>(null) // Track the previous calculated value

  const sanitizedCalculation = useMemo(
    () =>
      replaceWildcardsWithPrefix(prefix, (calculation || '').replace(/`/g, '')),
    [calculation, prefix]
  )

  useEffect(() => {
    if (!sanitizedCalculation.trim()) {
      return
    }

    const unsubscribe = form.subscribe(
      ({ values }: { values: FormValues }) => {
        const newValue = evaluateCalculation(sanitizedCalculation, values)

        if (newValue !== prevValueRef.current) {
          prevValueRef.current = newValue
          setTimeout(() => {
            input.onChange(newValue)
          }, 30)
        }
      },
      { values: true }
    )

    return () => {
      unsubscribe()
    }
  }, [form, sanitizedCalculation, input])
}

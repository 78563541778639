import React from 'react'
import { FormFieldMultipleContent } from './FormFieldMultipleContent'

interface FormFieldMultipleProps {
  description?: string
  fields: any[]
  name: string
  preview?: boolean
  processInstance: any
  application: any
  stepId?: string
  frame?: any
  user?: any
  displayName?: string
}

export const FormFieldMultiple: React.FC<FormFieldMultipleProps> = React.memo(
  ({ description, ...props }) => (
    <div className="form-runner-form-field-multiple">
      <FormFieldMultipleContent {...props} />
    </div>
  )
)

import { MouseEvent, FC, ReactNode, CSSProperties, useMemo } from 'react'
import classnames from 'classnames'
import { LoopLoader } from '../LoopLoader/LoopLoader'

export enum ButtonType {
  text = 'text',
  primary = 'primary',
  secondary = 'secondary',
  link = 'link',
  dashed = 'dashed',
  redirect = 'redirect',
  filter = 'filter',
  dashedFilter = 'dashedFilter',
  warning = 'warning',
}

export enum ButtonSize {
  medium = 'medium',
  large = 'large',
}

export interface ButtonProps {
  icon?: ReactNode
  iconRight?: boolean
  type?: ButtonType
  loading?: boolean
  block?: boolean
  danger?: boolean
  text?: string
  size?: string
  htmlType?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  style?: CSSProperties
  labelStyle?: CSSProperties
  onClick?: ((event: MouseEvent<HTMLButtonElement>) => void) | undefined
  className?: string
  children?: ReactNode
}

export const InnerButton: FC<ButtonProps> = ({
  icon,
  iconRight,
  text,
  loading,
  className,
  block = false,
  htmlType = 'button',
  type = ButtonType.secondary,
  size = 'medium',
  style,
  danger,
  labelStyle,
  children,
  onClick = () => {},
  ...rest
}) => {
  const iconOnly = useMemo(() => !children && icon, [children, icon])
  const classes = classnames(
    className,
    'Button',
    type,
    size,
    block && 'button--block',
    danger && 'button--danger',
    iconOnly && 'button--iconOnly'
  )

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick(event)
  }

  return (
    <button
      onClick={handleOnClick}
      style={style}
      className={classes}
      {...rest}
      type={htmlType}
    >
      {loading && <LoopLoader />}
      {icon && !iconRight && (!iconOnly || !loading) && (
        <span className="button-icon">{icon}</span>
      )}
      {!!children && (
        <span className="button-text" style={labelStyle}>
          {children}
        </span>
      )}
      {icon && iconRight && (!iconOnly || !loading) && (
        <span className="button-icon">{icon}</span>
      )}
    </button>
  )
}

export default InnerButton

exports.randomString = (length, chars) => {
  let result = ''
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

exports.capitalizeFirstLetter = ([first, ...rest], locale = undefined) =>
  first.toLocaleUpperCase(locale) + rest.join('')

exports.lowerFirstLetter = ([first, ...rest], locale = undefined) =>
  first.toLocaleLowerCase(locale) + rest.join('')

exports.findDecimalSeparator = (local = 'fr') => {
  const num = 1.2
  if (typeof Intl === 'object' && Intl && Intl.NumberFormat) {
    const formatter = new Intl.NumberFormat(local)
    const parts = formatter.formatToParts(num)
    const decimal = parts.find(({ type }) => type === 'decimal').value
    return decimal
  }
  const str = num.toLocaleString()
  const parts = /1(\D+)2/.exec(str)
  return parts[1]
}

exports.escapeRegex = string => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

exports.convertToFloat = (str, local) => {
  let body = str
  let sign = ''
  let isWrittingDecimal = false
  const decimal = exports.findDecimalSeparator(local)
  const signMatch = /^\s*(-|\+)/.exec(str)
  if (signMatch) {
    body = str.substring(signMatch.index + 1)
    sign = signMatch[1]
  }
  const rex = new RegExp(`${exports.escapeRegex(decimal)}|-|\\+|\\D`, 'g')
  const updatedBody = body.replace(rex, match => (match === decimal ? '.' : ''))
  if (
    updatedBody[updatedBody.length - 1] === '.' &&
    updatedBody.split('.').filter(Boolean).length === 1
  ) {
    isWrittingDecimal = true
  }
  const num = parseFloat(sign + updatedBody)
  return { num, isWrittingDecimal, decimal }
}

import gql from 'graphql-tag'

export const getTemplatesQuery = gql`
  query GetTemplates($processId: ID) {
    getTemplates(processId: $processId) {
      id
      title
      type
      mimetype
      dataSize
      tags
      processId
    }
  }
`

export const getTemplateQuery = gql`
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      applicationId
      content
      id
      mimetype
      status
      title
      type
      tags
      filename
      filetype {
        id
        title
      }
      languages
      url
      processId
    }
  }
`

import { useTranslation } from 'react-i18next'
import { getModel } from 'data-model'
import { extendedLocaleToLang } from 'data-model/src/shared/formatters'
import { findFieldTypeInFrame, updateFieldForm } from '../helpers/form'

interface Form {
  change: (field: string, value: any) => void
}

export const useUpdateField = () => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    form: Form,
    frame: any,
    processInstance: any,
    field: any,
    value: any
  ) => {
    const typeDef = findFieldTypeInFrame(field, frame) || { type: 'text' }

    updateFieldForm(
      {
        form,
        field,
        language: extendedLocaleToLang(language),
      },
      {
        ...typeDef,
      },
      getModel(typeDef) || {},
      value
    )
  }
}

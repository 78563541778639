const moment = require('moment')
const writtenNumber = require('written-number')
const momentTz = require('moment-timezone')

const isLocale = locale => {
  let result

  try {
    Intl.getCanonicalLocales(locale)

    result = true
  } catch {}

  return result
}

exports.localeToExtendedLocale = locale =>
  isLocale(locale)
    ? ({
        fr: 'fr-FR',
        en: 'en-US',
      }[locale.toLowerCase()] ?? locale)
    : 'FR-fr'

exports.extendedLocaleToLang = locale =>
  isLocale(locale) ? new Intl.Locale(locale).language.toUpperCase() : 'FR'

exports.formatDate = (
  value = Date.now(),
  { locale = 'fr', horodated = false, dispMonthName = true } = {}
) => {
  let format = dispMonthName ? 'LL' : 'l'
  const at = locale === 'fr' ? ' à' : ' at'
  horodated ? (format = format.concat(`[${at}] HH:mm`)) : {}
  return moment(value).locale(locale).format(format)
}

exports.formatDateWithoutYear = (
  value = Date.now(),
  { locale = 'fr', horodated = false } = {}
) => {
  let format = locale === 'fr' ? 'DD MMMM' : 'MMMM Do'
  const at = locale === 'en' ? ' at' : ' à'
  horodated ? (format = format.concat(`[${at}] HH:mm`)) : {}
  return moment(value).locale(locale).format(format)
}

exports.formatNumber = (
  value,
  { locale = 'fr', unit = '', subUnit = 'centième' } = {}
) => {
  const format = value =>
    Number(Math.round(value + 'e' + 2) + 'e-' + 2).toFixed(2)
  const getDecimalValue = value => Number(format(value).slice(-2))

  const integer = Math.trunc(value)
  const decimal = getDecimalValue(value)

  return `${writtenNumber(integer, { lang: locale })} ${unit}${
    Boolean(unit) && integer !== 1 && integer !== 0 ? 's' : ''
  }${decimal ? ' et ' : ''}${
    decimal
      ? `${writtenNumber(decimal, { lang: locale })} ${subUnit}${
          decimal !== 1 && decimal !== 0 ? 's' : ''
        }`
      : ''
  }`
}

exports.writeMoney = (
  value,
  { currency = 'euro', sub = 'centime', locale = 'fr' } = {}
) => exports.formatNumber(value, { locale, unit: currency, subUnit: sub })

exports.format = (value, format = 'HH:mm') => value.format(format)

exports.formatTime = (value = Date.now(), { format, locale = 'en' } = {}) => {
  const timeZones = momentTz.tz.zonesForCountry(locale.toUpperCase())
  let timeZone = 'Europe/Paris'
  if (timeZones && 0 < timeZones.length) {
    timeZone = timeZones[0]
  }
  return exports.format(
    momentTz.tz(value, 'YYYY-MM-DD HH:mm:ssZZ', timeZone),
    format
  )
}

exports.formatMoney = (
  value,
  { currency = 'EUR', minDigits = 0, maxDigits = 2, locale = 'fr' } = {}
) => {
  return isNaN(value)
    ? value
    : new Intl.NumberFormat(exports.localeToExtendedLocale(locale), {
        currency,
        maximumFractionDigits: maxDigits,
        minimumFractionDigits: minDigits,
        style: 'currency',
      }).format(value)
}

exports.nth = (n, { locale = 'en' } = {}) => {
  switch (locale.toLowerCase()) {
    case 'fr':
      return n === 1 ? '1er' : `${n}ème`
    case 'en':
    default:
      return (
        n + (['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th')
      )
  }
}

import React from 'react'
import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client'
import { DocumentNode } from 'graphql'

export const useImperativeQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {}
): [
  (variables: TVariables) => Promise<ApolloQueryResult<TData>>,
  QueryResult<TData, TVariables>,
] => {
  const props = useQuery<TData, TVariables>(query, { ...options, skip: true })

  const { refetch } = props

  const get = React.useCallback(
    (variables: TVariables) => refetch(variables),
    [refetch]
  )

  return [get, props]
}

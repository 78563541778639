// Define types for the function parameters
type ClickMethod = (link: HTMLAnchorElement) => void
type AfterCallback = (link: HTMLAnchorElement) => void

export const triggerDownloadLink = (
  href: string,
  method?: ClickMethod, // optional method
  after?: AfterCallback // optional after callback
): void => {
  const link = document.createElement('a')

  link.href = href

  if (method) {
    method(link)

    document.body.appendChild(link)
  }

  link.click()

  if (after) after(link)

  if (method) document.body.removeChild(link)
}

export const downloadBlobObject = (
  content: BlobPart,
  type: string = 'pdf'
): void => {
  triggerDownloadLink(
    URL.createObjectURL(new Blob([content])), // Convert content to Blob
    (link: HTMLAnchorElement) => {
      link.download = 'data.' + type
    },
    (link: HTMLAnchorElement) => URL.revokeObjectURL(link.href)
  )
}

export const downloadTextFile = (
  content: string,
  type: string = 'csv'
): void => {
  downloadBlobObject(new Blob([content]), type) // Convert string content into Blob
}

export const downloadJsonFile = (
  content: unknown,
  ...parameters: any[]
): void => {
  downloadTextFile(JSON.stringify(content, ...parameters), 'json')
}

export const downloadBase64Data = (
  fileName: string,
  type: string,
  data: string
) => {
  triggerDownloadLink('data:' + type + ';base64,' + data, link => {
    link.download = fileName
  })
}

const { faker } = require('@faker-js/faker')
const isFinite = require('lodash/isFinite')
const isNil = require('lodash/isNil')

exports.number = {
  serialize:
    ({ type }) =>
    value => ({
      value: value === '' || isNil(value) ? undefined : Number(value),
      type,
    }),
  deserialize: ({ value } = {}) => (isNil(value) ? '' : String(value)),
  validate:
    () =>
    ({ value } = {}) =>
      isFinite(value) ? null : 'requiredField',
  fake: faker.datatype.number,
}

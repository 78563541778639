import React, { useMemo } from 'react'
import classNames from 'classnames'

import { FilterPillsAll } from './FilterPillsAll'
import './FilterPills.less'
import InnerButton, { ButtonType } from '../InnerButton/InnerButton'
import { EllipsisTooltip } from '../EllipsisTooltip/EllipsisTooltip'
import PlusIcon from '../Icon/PlusIcon'
import { useTranslation } from 'react-i18next'

export interface PillData {
  data: {
    title?: string
    categoryId: string
    count?: number
    productsPublish?: number
  }
}

interface FilterPillsProps {
  allPillText: string
  pills: Record<string, PillData>
  currentPill: string
  setCurrentPill: (pillId: string | null) => void
  displayCount?: boolean
  displayAll?: boolean
  displayAdd?: boolean
  onAddClick: () => void
  allPillAtEnd?: boolean
}

export const FilterPills: React.FC<FilterPillsProps> = ({
  allPillText,
  pills,
  currentPill,
  setCurrentPill,
  displayCount = true,
  displayAll = true,
  displayAdd = false,
  onAddClick,
  allPillAtEnd = false,
}) => {
  const { t } = useTranslation()
  const getPillText = useMemo(() => {
    return (pill: [string, PillData]) => {
      let text = pill[1]?.data?.title || pill[1]?.data?.categoryId
      if (displayCount && pill[1] && (pill[1]?.data?.count || 0) >= 0) {
        text += ` (${pill[1]?.data?.count || 0})`
      }
      return text
    }
  }, [displayCount])

  const productsFiltered = useMemo(
    () =>
      Object.entries(pills).filter(
        ([key]) => key !== 'undefined' && key !== 'null'
      ),
    [pills]
  )

  return (
    <div className="filter-pills">
      {displayAll && !allPillAtEnd && (
        <FilterPillsAll
          allPillText={allPillText}
          pills={pills}
          displayCount={displayCount}
          setCurrentPill={setCurrentPill}
          currentPill={currentPill}
        />
      )}
      {productsFiltered?.map((pill, key) => (
        <InnerButton
          key={key}
          type={ButtonType.filter}
          onClick={() => setCurrentPill(pill[1].data?.categoryId || '')}
          className={classNames(
            currentPill === pill[1].data?.categoryId && 'selected'
          )}
        >
          <EllipsisTooltip placement="top" line={2}>
            <>{getPillText(pill)}</>
          </EllipsisTooltip>
        </InnerButton>
      ))}
      {displayAll && allPillAtEnd && (
        <FilterPillsAll
          allPillText={allPillText}
          pills={pills}
          displayCount={displayCount}
          setCurrentPill={setCurrentPill}
          currentPill={currentPill}
        />
      )}
      {displayAdd && typeof onAddClick === 'function' && (
        <InnerButton
          onClick={onAddClick}
          className={'create-category-button'}
          icon={<PlusIcon />}
          type={ButtonType.dashedFilter}
        >
          {t('categoryCreate.title')}
        </InnerButton>
      )}
    </div>
  )
}

import gql from 'graphql-tag'

export const updateDocumentMutation = gql`
  mutation UpdateDocument(
    $id: ID!
    $filename: JSON!
    $flags: [Fileflag]
    $tags: JSON
    $filetypeId: ID
    $isLatest: Boolean
    $processInstanceId: ID
    $entityId: ID
  ) {
    updateDocument(
      id: $id
      filename: $filename
      flags: $flags
      tags: $tags
      filetypeId: $filetypeId
      isLatest: $isLatest
      processInstanceId: $processInstanceId
      entityId: $entityId
    ) {
      id
      url
      filename
      flags
      isLatest
      filetype {
        id
        title
      }
    }
  }
`

export const uploadDocumentMutation = gql`
  mutation UploadDocument(
    $file: Upload!
    $documentSource: DocumentSourceInput!
    $flags: [Fileflag]
    $filetypeId: ID
    $tags: JSON
    $filename: JSON
    $isLatest: Boolean
    $metadata: JSONObject
  ) {
    uploadDocuments(
      files: [$file]
      documentSource: $documentSource
      flags: [$flags]
      filetypeId: [$filetypeId]
      tags: [$tags]
      isLatest: $isLatest
      filenames: [$filename]
      metadata: [$metadata]
    ) {
      id
      url
      filename
      flags
      isLatest
      filetype {
        id
        title
      }
      metadata
    }
  }
`

export const uploadDocumentsMutation = gql`
  mutation UploadDocuments(
    $files: [Upload!]!
    $documentSource: DocumentSourceInput!
    $flags: [Fileflag]
    $filetypeId: [ID]
    $tags: [JSON]
    $filenames: [JSON]
    $metadata: [JSONObject]
  ) {
    uploadDocuments(
      files: $files
      documentSource: $documentSource
      flags: $flags
      filetypeId: $filetypeId
      tags: $tags
      filenames: $filenames
      metadata: $metadata
    ) {
      id
      url
      filename
      flags
      isLatest
      filetype {
        id
        title
      }
    }
  }
`

export const replaceDocumentMutation = gql`
  mutation ReplaceDocument(
    $file: Upload!
    $id: ID!
    $documentSource: DocumentSourceInput
  ) {
    replaceDocument(file: $file, id: $id, documentSource: $documentSource) {
      id
      url
      filename
    }
  }
`

export const deleteDocumentMutation = gql`
  mutation DeleteDocument($id: ID!, $version: Int!) {
    deleteDocument(id: $id, version: $version) {
      id
    }
  }
`

export const validateDocumentsByPiMutation = gql`
  mutation ($documentIds: [ID]!, $processInstanceId: ID!) {
    validateDocumentsByPi(
      documentIds: $documentIds
      processInstanceId: $processInstanceId
    ) {
      id
    }
  }
`

export const setDocumentMutation = gql`
  mutation ($processInstanceId: ID!, $documentId: ID!, $content: JSON!) {
    setDocument(
      processInstanceId: $processInstanceId
      documentId: $documentId
      content: $content
    )
  }
`

import { Modal } from './Modal'
import { FC, ReactNode, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import InnerButton, { ButtonType } from '../InnerButton/InnerButton'

interface ConfirmModalProps {
  title?: string
  cancelText?: string
  onConfirm?: () => void
  onCancel?: () => void
  onClose?: () => void
  confirmText?: string
  isShown?: boolean
  content?: ReactNode
  setIsShown: (value: boolean) => void
  className?: string
  subTitle?: string
  hideCancel?: boolean
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  cancelText,
  onConfirm,
  onCancel,
  onClose,
  confirmText,
  isShown = false,
  content,
  setIsShown,
  className,
  subTitle,
  hideCancel,
  ...props
}) => {
  const { t } = useTranslation()
  const handleConfirm = (
    evt: MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    evt.preventDefault()
    setIsShown(false)
    if (onConfirm) {
      onConfirm()
    }
    evt.stopPropagation()
  }

  const handleCancel = (
    evt: MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    evt.preventDefault()
    setIsShown(false)
    if (onCancel) {
      onCancel()
    }
    evt.stopPropagation()
  }

  const handleHide = () => {
    if (onClose) {
      onClose()
    } else {
      setIsShown(false)
      if (onCancel) {
        onCancel()
      }
    }
  }

  return (
    <Modal
      style={{
        mainStyle: {
          width: 550,
        },
      }}
      hide={handleHide}
      headerText={title}
      className={className}
      isShown={isShown}
      {...props}
      modalContent={
        <>
          {subTitle}
          {content && <div className="confirmation-content">{content}</div>}
          <div className="confirmation-buttons">
            {!hideCancel && (
              <InnerButton type={ButtonType.secondary} onClick={handleCancel}>
                {cancelText || t('common.cancel')}
              </InnerButton>
            )}
            <InnerButton type={ButtonType.primary} onClick={handleConfirm}>
              {confirmText || t('common.confirm.yes')}
            </InnerButton>
          </div>
        </>
      }
    />
  )
}

export default ConfirmModal

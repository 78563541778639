import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const ArrowOppositeIcon = ({
  title,
  style,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    style={style}
    color="currentColor"
    enableBackground="new 0 0 32 32"
    version="1.1"
    viewBox="0 0 32 32"
  >
    <g>
      <polygon
        fill="none"
        points="1,16 14,8 14,24"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <polygon
        fill="none"
        points="31,16 18,8 18,24"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default ArrowOppositeIcon

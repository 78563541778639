import { get, isEqual } from 'lodash'
import i18n from 'i18next'
import { FormState, Mutator } from 'final-form'

// Define types for function parameters
interface SetValueParams {
  name: string
  type: string
  options?: any
  definition?: {
    serialize?: (params: any) => (value: any) => any
    dataOptions?: any
  }
}

interface FormAPI {
  getState: () => FormState<any>
  change: Mutator
}

export const setValue = (
  { name, type, options, definition }: SetValueParams,
  value: any,
  { getState, change }: FormAPI
): void => {
  const values = getState().values
  const valueFromData = get(values, name)

  // Avoid checking type => set only once if needed
  if (
    (!valueFromData ||
      valueFromData.value === null ||
      valueFromData.value === undefined ||
      !isEqual(valueFromData.value, value)) &&
    ((type === 'uuid' && !valueFromData?.value) || type !== 'uuid')
  ) {
    const { serialize, dataOptions } = definition || {}

    if (!serialize) {
      throw new Error(
        `[setValue] No serialize function for type ${type} (name: ${name})`
      )
    }

    const valueSerialized = serialize({
      ...dataOptions,
      language: i18n.language,
      options,
      type,
    })(value)

    // @ts-ignore
    change(name, valueSerialized)
  }
}

import React, { useState } from 'react'

import CheckIcon from '../Icon/CheckIcon'
import { ColorSelection } from '../../constants/colors'
import classNames from 'classnames'
import { Tooltip } from '../Tooltip/Tooltip'

interface Title {
  [key: string]: string
}

interface ColorSelectorProps {
  onChange?: (value: string) => void
  value?: string
  disabled?: boolean
  titles: Title
}

export const ColorSelector: React.FC<ColorSelectorProps> = ({
  onChange,
  disabled,
  value,
  titles,
}) => {
  const [valueState, setValueState] = useState(value)

  const colors = Object.entries(ColorSelection)

  const onClick = (val: string) => {
    setValueState(val)
    typeof onChange === 'function' && onChange(val)
  }
  return (
    <div
      className={`alf-color-selector${disabled ? ' alf-color-selector--disabled' : ''}`}
    >
      {colors.map(color =>
        titles ? (
          <Tooltip key={color[0]} title={titles[color[0]]}>
            <div
              onClick={() => onClick(color[0])}
              className={classNames('alf-color-selector-item', color[0])}
            >
              {valueState && color[0] === valueState && <CheckIcon />}
            </div>
          </Tooltip>
        ) : (
          <div
            key={color[0]}
            onClick={() => onClick(color[0])}
            className={classNames('alf-color-selector-item', color[0])}
          >
            {valueState && color[0] === valueState && <CheckIcon />}
          </div>
        )
      )}
    </div>
  )
}

export const incrementOffset = (offset: string) => {
  // Convert the offset string (e.g., "12,12,2") into an array of numbers
  const offsetParts = offset.split(';').map(Number)

  // Increment the last number in the array
  offsetParts[offsetParts.length - 1]++

  // Join the array back into a string (e.g., "12,12,3")
  return offsetParts.join(';')
}

import gql from 'graphql-tag'

export const updateFiletypeMutation = gql`
  mutation updateFiletype($id: ID, $applicationId: ID!, $title: JSON!) {
    updateFiletype(id: $id, applicationId: $applicationId, title: $title) {
      id
      title
    }
  }
`
export const createFiletypeMutation = gql`
  mutation createFiletype($applicationId: ID!, $title: JSON!) {
    createFiletype(applicationId: $applicationId, title: $title) {
      id
      title
    }
  }
`
export const getOrcreateFiletypeByTitleAndApplicationIdMutation = gql`
  mutation getOrcreateFiletypeByTitleAndApplicationId(
    $applicationId: ID!
    $title: JSON!
  ) {
    getOrcreateFiletypeByTitleAndApplicationId(
      applicationId: $applicationId
      title: $title
    ) {
      id
      title
    }
  }
`

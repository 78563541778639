export const baseContentStyle = `
body{
  margin: 1rem;
  caret-color: black;
}
.jmespath-var{
  font-style: italic;
}
.jmespath-tag{
  color: var(--primary-color);
  background: var(--primary-color-fade);
  height: 16px;
  padding: 0 8px;
  border-radius: 34px;
  font-weight: normal;
  font-size: 12px;
  display: inline-block;
}
.user-notify{
  font-weight: bold;
}
.user-notify-id{
  display: none;
}
.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
  font-size: 14px;
  color: #737373;
}
p{
  margin: 0;
  font-size: 14px;
}
`

export const getJmespathArrayValues = (jmespath: string) => {
  const arrRexp = /\[(.*)] \| join\(' ', @\)/gm
  const t = [...(jmespath || '').matchAll(arrRexp)]
  const values = t?.[0]?.[1]
  if (values) {
    return values.split(', ')
  }
  return null
}

export const isNameInLabel = (name = '', label = '') => {
  const values = getJmespathArrayValues(label)
  return (
    name === label ||
    (label || '').includes('${' + name + '}') ||
    (values || []).includes(name)
  )
}

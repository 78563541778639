import { getModel } from 'data-model'

import { camelCase, isPlainObject, isString } from 'lodash'

export const isEmptyOrWhitespace = (str: string) =>
  !isString(str) || !str || str.match(/^ *$/) !== null

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms))

export const getModelValue = (value: any, options?: any): any => {
  if (value.type) {
    const model = getModel(value)
    if (model && model.toStr) {
      return model.toStr(value, options)
    }
  }
  return value
}

export const appendArrayOfUniqueData = <T>(
  oldArray: T[] = [],
  newValue: T
): T[] => [...new Set([...(oldArray || []), newValue])]

export const camelizeNestedKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeNestedKeys(v))
  } else if (obj !== null && isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeNestedKeys(obj[key]),
      }),
      {} as Record<string, any>
    )
  }
  return obj
}

export * from './getDataValue'
export * from './getWorkflowInfo'
export * from './isValidFrameItem'
export * from './validateUtils'

import { useTranslation } from 'react-i18next'
import { groupData } from '../helpers'

interface Data {
  [key: string]: any
}

interface UseDataByFrameParams<Data> {
  data?: Data
  workflow?: any
  array?: Array<{
    dataResolved: Data
    workflow: any
  }>
}

export const useDataByFrame = ({
  data,
  workflow,
  array,
}: UseDataByFrameParams<Data>) => {
  const {
    i18n: { language },
  } = useTranslation()

  return array
    ? array.map(({ dataResolved, workflow }) =>
        groupData(dataResolved, workflow, language)
      )
    : data && groupData(data, workflow, language)
}

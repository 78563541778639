import { gql } from 'graphql-tag'

export const updateEntityMutation = gql`
  mutation UpdateEntity($id: ID!, $input: EntityUpdateInput!) {
    updateEntity(id: $id, input: $input) {
      id
    }
  }
`

export const createEntityMutation = gql`
  mutation CreateEntity($input: EntityView!) {
    createEntity(input: $input) {
      id
    }
  }
`

export const deleteEntityTypeMutation = gql`
  mutation DeleteEntityType($id: ID!) {
    deleteEntityType(id: $id) {
      id
      title
    }
  }
`

export const deleteEntityCategoryMutation = gql`
  mutation DeleteEntityCategory($id: ID!) {
    deleteEntityCategory(id: $id) {
      id
      title
    }
  }
`

export const createEntityTypeMutation = gql`
  mutation CreateEntityType($input: EntityTypeInput!) {
    createEntityType(input: $input) {
      id
    }
  }
`

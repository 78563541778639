import React from 'react'
import { FormField } from './FormField'
import { FormFieldConditionalContent } from './FormFieldConditionalContent'
import './FormField.less'

type FormFieldCheckConditionalPropType = {
  condition?: string[]
  name: string
  application: any
  processInstance: any
  prefix?: string
  language?: string
  frame?: any
  useFAQ?: any
  type: string
  fields: any[]
}

export const FormFieldCheckConditional: React.FC<FormFieldCheckConditionalPropType> =
  React.memo(({ condition, ...props }) =>
    Array.isArray(condition) && condition.length > 0 ? (
      <FormFieldConditionalContent condition={condition} {...props} />
    ) : (
      <FormField {...props} />
    )
  )

export const CURRENCIES = [
  {
    name: 'Dollar américain',
    code: 'USD',
    symbol: '$',
  },
  {
    name: 'Euro',
    code: 'EUR',
    symbol: '€',
  },
  {
    name: 'Livre sterling',
    code: 'GBP',
    symbol: '£',
  },
  {
    name: 'Franc suisse',
    code: 'CHF',
    symbol: 'CHF',
  },
  {
    name: 'Dollar canadien',
    code: 'CAD',
    symbol: '$ CAD',
  },
  {
    name: 'Franc CFA',
    code: 'XOF',
    symbol: 'CFA',
  },
]

import { useState, useCallback } from 'react'
import { FormSpy } from 'react-final-form'

import { FormField } from './FormField'
import { adjustDataKeys, conditionIsFullfilled } from '../../helpers'

// Define the expected types for props
interface FormFieldResetContentProps {
  formValues: Record<string, any>
  form: {
    change: (field: string, value: any) => void
  }
  application: any
  processInstance: any
  condition: any[] // Update this type if you have a more specific structure
  name: string
  prefix?: string
  type: string
  fields: any[]
}

// Functional component with typed props
const FormFieldResetContent: React.FC<
  FormFieldResetContentProps & Record<string, any>
> = ({ formValues, form, condition, ...props }) => {
  const [alreadyDisplayed, setAlreadyDisplayed] = useState<boolean | null>(null)

  const checkConditions = useCallback(
    (
      values: Record<string, any>,
      form: { change: (field: string, value: any) => void }
    ) => {
      const conditionsAreFulfilled = condition.filter(Boolean).every(cond => {
        const sanitizedCondition = adjustDataKeys(
          props.prefix || '',
          values,
          cond
        )
        return conditionIsFullfilled(sanitizedCondition, values)
      })

      if (alreadyDisplayed === true && !conditionsAreFulfilled && props.name) {
        form.change(props.name, '')
      }

      if (conditionsAreFulfilled !== alreadyDisplayed) {
        setAlreadyDisplayed(conditionsAreFulfilled)
      }

      return alreadyDisplayed
    },
    [alreadyDisplayed, condition, props.name, props.prefix]
  )

  if (!checkConditions(formValues, form)) {
    return null
  }

  return <FormField {...props} />
}

// Define props for FormFieldConditionalContent
interface FormFieldConditionalContentProps {
  condition: any[] // Update this type if possible
  application: any
  processInstance: any
  name: string
  prefix?: string
  type: string
  fields: any[]
}

// Functional component with typed props
export const FormFieldConditionalContent: React.FC<
  FormFieldConditionalContentProps & Record<string, any>
> = ({ condition, ...props }) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values: formValues, form }) => (
        <FormFieldResetContent {...{ condition, form, formValues, ...props }} />
      )}
    </FormSpy>
  )
}

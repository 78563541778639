import gql from 'graphql-tag'

export const getInseeMunicipalitiesByPostalCodeQuery = gql`
  query getInseeMunicipalitiesByPostalCode($postalCode: String) {
    getInseeMunicipalitiesByPostalCode(postalCode: $postalCode) {
      id
      inseeCode
      postalCode
      name
      label
    }
  }
`

export const getCountriesEuQuery = gql`
  query getCountriesEu {
    getCountriesEu {
      id
      isoCode2
      label
      label_en
      nationality
    }
  }
`

export const getCountriesQuery = gql`
  query getCountries {
    getCountries {
      id
      isoCode2
      label
      label_en
      nationality
    }
  }
`

export const getRegistrarQuery = gql`
  query GetRegistrar($codeCommune: String) {
    getRegistrar(codeCommune: $codeCommune) {
      id
      registrarCode
      name
    }
    getRegistrars {
      id
      registrarCode
      name
    }
  }
`

export const getCompanyMemberRolesQuery = gql`
  query getCompanyMemberRoles($companyType: Int) {
    getCompanyMemberRoles(companyType: $companyType)
  }
`

export const getInseeLegalFormsFrQuery = gql`
  query getInseeLegalFormsFr {
    getInseeLegalFormsFr {
      id
      label
      category
    }
  }
`

export const getCompanyInfoBySiretQuery = gql`
  query getCompanyInfoBySiret($siret: String!) {
    getCompanyInfoBySiret(siret: $siret)
  }
`

export const getCompanyBodaccEntriesQuery = gql`
  query getCompanyBodaccEntries($siret: String!) {
    getCompanyBodaccEntries(siret: $siret)
  }
`

export const getCompanyInfoFromPappersBySiretQuery = gql`
  query getCompanyInfoFromPappersBySiret($siret: String!) {
    getCompanyInfoFromPappersBySiret(siret: $siret)
  }
`

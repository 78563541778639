import deburr from 'lodash/deburr'

export const match = (query: string, text: string): boolean =>
  query
    .split(/\s+/)
    .map(x => deburr(x).toLowerCase())
    .every(x => deburr(text).toLowerCase().includes(x))

export const isJSON = (str: string): boolean => {
  try {
    if (!isNaN(Number(str))) {
      return false
    }
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}

export const humanFileSize = (
  bytes: number,
  si: boolean = false,
  dp: number = 1
): string => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return `${bytes.toFixed(dp)} ${units[u]}`
}

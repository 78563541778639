import jmespath from 'jmespath'
import { cleanData } from 'data-model'
import moment from 'moment'
import QRCode from 'qrcode-svg'
import {
  formatDate,
  formatDateWithoutYear,
  formatMoney,
  formatNumber,
  formatTime,
  writeMoney,
} from 'data-model/src/shared/formatters'
import { isObject } from 'lodash'

const REGEX_FILE_NAME_TEMPLATE_LITERAL = /\${[^}]*}/g

const getLiteralsFromTemplate = (template: string) =>
  template.matchAll(REGEX_FILE_NAME_TEMPLATE_LITERAL)

const getCleanedLiteral = (literal: string) => literal.slice(2, -1)

type DataObject = Record<string, any>

export const computeStringWithTemplate = (
  filename: string,
  data: DataObject,
  language: string
): string => {
  let result = filename || ''
  const literals = getLiteralsFromTemplate(filename)
  for (const literal of literals) {
    const toResolve = getCleanedLiteral(literal[0])
    const resolved = resolveValue(data, toResolve, language)
    result = result.replace(
      literal[0],
      resolved !== undefined ? resolved : '\u00A0'
    )
  }

  return result.startsWith('`') && result.endsWith('`')
    ? result.slice(1, -1)
    : result
}

export const resolveValue = (
  data: DataObject,
  toResolve: string,
  language: string
): string | undefined => {
  let resolved = jmespath.search(data, toResolve)

  if (typeof resolved?.toStr === 'function') {
    return resolved.toStr()
  } else if (typeof resolved === 'string' || resolved instanceof String) {
    return resolved.toString()
  }

  resolved = isObject(resolved) ? { ...resolved } : resolved

  if (resolved && resolved.type) {
    switch (resolved.type) {
      case 'dateTimePicker':
      case 'datePicker':
        resolved.value = moment(resolved.value).isValid()
          ? formatDate(resolved.value, {
              dispMonthName: false,
              locale: language,
            })
          : resolved.value
        break
      case 'timePicker':
        resolved.value = moment(resolved.value).isValid()
          ? formatTime(resolved.value, { locale: language })
          : resolved.value
        break
      default:
    }
  }

  return resolved
    ? resolved[language] ||
        resolved[language.toLowerCase()] ||
        resolved.value ||
        resolved
    : undefined
}

export const resolveJmespath = (
  data: DataObject,
  jmesPath: string,
  language: string,
  isDataCleaned: boolean = false
): any => {
  try {
    const sanitizedData = isDataCleaned
      ? data
      : cleanData(data, { keepToStrMethod: true, locale: language })

    if (new RegExp(REGEX_FILE_NAME_TEMPLATE_LITERAL).test(jmesPath)) {
      return computeStringWithTemplate(jmesPath, sanitizedData, language)
    }
    return jmespath.search(sanitizedData, jmesPath)
  } catch {
    return undefined
  }
}

export const accessor = (
  data: any,
  options: { __debug?: boolean; __hideUndefined?: boolean } = {},
  context: any,
  locale: { locale: string } = { locale: 'en' }
) => {
  const { __debug, __hideUndefined } = options

  const setLocale = (newLocale: string = 'en') => {
    locale.locale = newLocale
  }

  const get = (path: string, options: any = {}): any => {
    const value = jmespath.search(data, path)
    if (value) {
      if (Array.isArray(value)) {
        return value
      }
      if (value.toStr) {
        return value.toStr({
          locale: (locale && locale.locale) || 'en',
          ...options,
        })
      }
      return value.value !== undefined ? value.value : value
    }
    return __debug
      ? `<span class='alf-tag' data-value='${path}' style="font-weight: bold; background-color: red;" >${
          !__hideUndefined ? 'UNDEFINED:' : ''
        }${path}</span>`
      : ''
  }

  const getRaw = (path: string): any => {
    const getPrimitiveString = (value: any): any =>
      value instanceof String ? String(value) : value

    const value = jmespath.search(data, path)

    if (value) {
      if (value.value) {
        return getPrimitiveString(value.value)
      }
      return getPrimitiveString(value)
    }
    return undefined
  }

  const getArray = (path: string): any[] => {
    const value = jmespath.search(data, path)
    if (Array.isArray(value)) {
      return value.map(el => accessor(el, options, context, locale))
    }

    return (__debug ? [{}] : []).map(data =>
      accessor(data, {}, context, locale)
    )
  }

  const qrCode = (text: string, size: number): string =>
    new QRCode({
      background: '#ffffff',
      color: '#000000',
      content: text,
      ecl: 'M',
      height: size,
      join: true,
      padding: 0,
      width: size,
      xmlDeclaration: false,
    }).svg()

  const set = (obj: any): void => {
    data = JSON.parse(JSON.stringify({ ...data, ...obj }))
  }

  const displayAddress = ({
    element,
    ignoreCountry,
    prefix,
    locale = 'fr',
  }: {
    element?: any
    ignoreCountry?: boolean
    prefix?: string
    locale?: string
  }): string => {
    const display = (get: (path: string, options?: any) => any): string =>
      ['address', 'zipCode', 'city', 'country']
        .filter(key => !ignoreCountry || key !== 'country')
        .map(key =>
          get(
            prefix
              ? `${prefix}${key.replace(/^./, x => x.toUpperCase())}`
              : key,
            { locale }
          )
        )
        .join(', ')
    return element ? display(element.get) : display(get)
  }

  const getPersonInfo = ({
    member,
    locale = 'fr',
    format,
  }: {
    member: any
    locale?: string
    format?: string
  }): string => {
    switch (format) {
      case 'legalNotice':
        return `${member.get('firstName')} ${member.get('lastName')}${
          member.get('spouseLastName').length &&
          !member.get('spouseLastName').startsWith('<')
            ? `, ${locale === 'fr' ? 'épouse ' : 'spouse '} ${member.get(
                'spouseLastName'
              )}`
            : ''
        }`
      default:
        return `${member.get('firstName')} ${member.get('lastName')}${
          member.get('spouseLastName').length &&
          !member.get('spouseLastName').startsWith('<')
            ? `, ${locale === 'fr' ? 'épouse ' : 'spouse '} ${member.get(
                'spouseLastName'
              )}`
            : ''
        }, ${locale === 'fr' ? 'né(e) le' : 'born on'} ${member.get(
          'birthDate',
          { locale }
        )} ${locale === 'fr' ? 'à' : 'in'} ${member.get(
          'birthCity'
        )}, ${member.get('birthZipCode')}, ${member.get('birthCountry')}, ${
          locale === 'fr' ? 'de nationalité' : 'of'
        } ${member.get('nationality')}`
    }
  }

  const getPersonFullInfo = ({
    member,
    locale = 'fr',
    format,
  }: {
    member: any
    locale?: string
    format?: string
  }): string =>
    `${getPersonInfo({ format, locale, member })}, ${
      locale === 'fr' ? 'demeurant' : 'whose address is'
    } ${displayAddress({
      element: member,
      locale,
    })}`

  const getCompanyFullInfo = ({
    member,
    locale = 'fr',
    format,
  }: {
    member: any
    locale?: string
    format?: string
  }): string => {
    switch (format) {
      case 'legalNotice':
        return `${member.get('companyName')}, ${
          locale === 'fr' ? 'sise' : 'whose headquarter is located at'
        } ${displayAddress({
          element: member,
          ignoreCountry: true,
          locale,
          prefix: 'companyHeadquarter',
        })}, ${member
          .get('companyRegistrationId')
          .substr(0, 11)} RCS ${member.get('companyRegistrationCity')}, ${
          locale === 'fr' ? 'représentée par' : 'represented by'
        } ${getPersonInfo({ format, locale, member })}`
      default:
        return `${member.get('companyName')}, ${
          locale === 'fr'
            ? 'dont le siège social est situé au'
            : 'whose headquarter is located at'
        } ${displayAddress({
          element: member,
          locale,
          prefix: 'companyHeadquarter',
        })}, ${
          locale === 'fr'
            ? 'immatriculée au registre des sociétés de'
            : 'registered with the Companies Registry of'
        } ${member.get('companyRegistrationCity')}, ${
          locale === 'fr' ? 'sous le numéro' : 'under the number'
        } ${member.get('companyRegistrationId')}, ${
          locale === 'fr' ? 'représentée par' : 'represented by'
        } ${getPersonInfo({ locale, member })}`
    }
  }

  const getFullInfo = ({
    member,
    locale = 'fr',
    format,
  }: {
    member: any
    locale?: string
    format?: string
  }): string => {
    if (
      (member.getRaw('type') || '').toLowerCase() === 'person' ||
      member.getRaw('entityType') === 'Person'
    ) {
      return getPersonFullInfo({ format, locale, member })
    } else if (
      (member.getRaw('type') || '').toLowerCase() === 'company' ||
      member.getRaw('entityType') === 'Company'
    ) {
      return getCompanyFullInfo({ format, locale, member })
    } else {
      const message = `Invalid Person or Company. (type: ${member.getRaw(
        'type'
      )})`
      return `<span style="font-weight: bold; background-color: red;" >:${message}</span>`
    }
  }

  const getCompanyClosingDateRange = (
    date: string
  ): { endDate: moment.Moment; startDate: moment.Moment } => {
    if (!date) {
      return { endDate: moment(), startDate: moment() }
    }
    const startDate = moment(date, 'YYYY-MM-DD').add(1, 'days')
    if (startDate.month() === 1 && startDate.date() === 29) {
      startDate.add(1, 'days')
    }

    const endDate = moment(date, 'YYYY-MM-DD').add(1, 'years')
    if (endDate.month() === 1 && endDate.date() === 29) {
      endDate.subtract(1, 'days')
    }

    return {
      endDate,
      startDate,
    }
  }

  const getMinimalInfo = ({
    member,
    locale = 'fr',
    ignoreCountry,
  }: {
    member: any
    locale?: string
    ignoreCountry?: boolean
  }): string => {
    if (member.getRaw('type').toLowerCase() === 'person') {
      return `${(member.get('lastName') || '').toUpperCase()} ${member.get(
        'firstName'
      )}, ${displayAddress({
        element: member,
        ignoreCountry:
          ignoreCountry !== undefined
            ? ignoreCountry
            : (member.get('country') || {}).fr === 'France',
      })}`
    } else if (member.getRaw('type').toLowerCase() === 'company') {
      return `${member.get('companyName')}, ${
        member.get('companyForm').label
      } ${locale === 'fr' ? 'sise' : 'at'} ${displayAddress({
        element: member,
        locale,
        prefix: 'companyHeadquarter',
      })}, ${member.get('companyRegistrationId')} RCS ${member.get(
        'companyRegistrationCity'
      )}`
    } else {
      const message = `Invalid Person or Company. (type: ${member.getRaw(
        'type'
      )})`
      return `<span style="font-weight: bold; background-color: red;" >:${message}</span>`
    }
  }

  const getMembersCEO = (
    members: any[],
    fn?: (member: any) => any,
    option: { paramType?: string; paramName?: string } = {}
  ): any[] => {
    if (!Array.isArray(members)) {
      // eslint-disable-next-line no-console
      console.error('getMembersCEO: First parameter must be an array')
      return []
    }
    const paramType = option['paramType'] || 'param' // Param can be passed as it is or inside an object to fn
    const paramName = option['paramName'] || 'member'

    const ceo = members.filter(member =>
      ['CEO', 'Manager'].includes(member.getRaw('role'))
    )

    if (fn) {
      return ceo.map(member =>
        paramType === 'param' ? fn(member) : fn({ [paramName]: member })
      )
    }
    return ceo
  }

  const formatMultiline = (lines: string, tag: string = 'p'): string =>
    (lines || '')
      .split('\n')
      .map(line => `<${tag}>${line}</${tag}>`)
      .join('\n')

  return {
    displayAddress,
    formatDate,
    formatMoney,
    formatMultiline,
    formatDateWithoutYear,
    formatNumber,
    formatTime,
    get,
    getArray,
    getCompanyClosingDateRange,
    getCompanyFullInfo,
    getFullInfo,
    getMembersCEO,
    getMinimalInfo,
    getPersonFullInfo,
    getPersonInfo,
    getRaw,
    moment,
    qrCode,
    set,
    setLocale,
    writeMoney,
  }
}

const isString = require("lodash/isString");
const isPlainObject = require("lodash/isPlainObject");
const {
  definitions
} = require("../definitions");
const {
  getModel
} = require("../getModel");
exports.fieldIsTyped = (obj, checkValueIsDefined = true) => obj && Boolean(obj.type) && isString(obj.type) && Boolean(definitions[obj.type]) && (checkValueIsDefined ? obj.value !== undefined : true);
exports.isTyped = data => Object.keys(data || {}).some(key => Array.isArray(data[key]) ? data[key].some(el => exports.isTyped(el)) : exports.fieldIsTyped(data[key]));
const removeType = (options = {}) => value => {
  const {
    keepToStrMethod = false,
    locale
  } = options;
  if (exports.fieldIsTyped(value, false)) {
    if (value.value === undefined) {
      return undefined;
    }
    const model = keepToStrMethod || locale ? getModel(value) : null;
    const valueValue = value.value;
    const res = !keepToStrMethod || isPlainObject(valueValue) ? valueValue : new String(valueValue);
    if (keepToStrMethod) {
      if (model?.toStr) {
        Object.defineProperty(res, "toStr", {
          value: () => model.toStr({
            ...value,
            options: value.options
          }, options)
        });
      } else {
        Object.defineProperty(res, "toStr", {
          value: () => value.value
        });
      }
      if (value.visible === false) {
        Object.defineProperty(res, "visible", {
          value: false
        });
      }
    }
    return res;
  }
  if (isPlainObject(value)) {
    return Object.keys(value).reduce((acc, key) => ({
      ...acc,
      [key]: exports.cleanData(value[key], options)
    }), {});
  }
  if (Array.isArray(value)) {
    return value.map(el => exports.cleanData(el, options));
  }
  return value;
};
exports.cleanData = (data = {}, options = {}) => removeType(options)(JSON.parse(JSON.stringify(data)));
const { faker } = require('@faker-js/faker')

exports.boolean = {
  serialize:
    ({ type }) =>
    value =>
      value ? { value, type } : undefined,
  deserialize: ({ value } = {}) => value,
  validate:
    () =>
    ({ value } = {}) =>
      value !== undefined ? null : 'requiredField',
  fake: faker.random.boolean,
}

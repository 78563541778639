import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import { useMutation } from '@apollo/client'

import { getFieldValuesMutation } from '../../../graphql/mutations/other'
import { useUpdateField } from '../../../hooks/useUpdateField'

import {
  AIIcon,
  ButtonType,
  InnerButton,
  Modal,
  Tooltip,
} from '../../../components'
import { CONTENT_TYPE_PDF, WORD_DOCUMENT_TYPES } from '../../../constants'

interface AutoFillWithAIButtonProps {
  processInstance?: any
  type: string
  frame?: any
  document?: any
}
export const AutoFillWithAIButton: React.FC<AutoFillWithAIButtonProps> = ({
  processInstance,
  type,
  document,
  frame,
}) => {
  const { t } = useTranslation()

  const [scanning, setScanning] = useState<boolean>(false)
  const [displayModal, setDisplayModal] = useState<any>({})

  const form = useForm()
  const [getFieldValues] = useMutation(getFieldValuesMutation)
  const updateField = useUpdateField()

  const documents = useMemo(
    () =>
      (type === 'idCheck'
        ? [document.front, document.back]
        : [document]
      ).filter(Boolean),
    [document, type]
  )

  const documentsValid = useMemo(
    () =>
      documents.filter(({ mimetype }) =>
        [CONTENT_TYPE_PDF, ...WORD_DOCUMENT_TYPES].includes(mimetype)
      ),
    [documents]
  )

  const onClick = async () => {
    setScanning(true)

    let modalConfig = {}
    try {
      const { data } = await getFieldValues({
        variables: {
          fields: form.getState().values,
          documents: documentsValid.map(({ id }) => id),
          names: form.getRegisteredFields(),
        },
      })

      if (data?.getFieldValues && Object.entries(data.getFieldValues).length) {
        Object.entries(data.getFieldValues).forEach(([field, value]) => {
          if (typeof value === 'string' && value) {
            updateField(form, frame, processInstance, field, value)
          }
        })
        modalConfig = {
          isShown: true,
          modalContent: t('formRunner.fieldView.fill.success.description'),
          headerText: t('formRunner.fieldView.fill.success.title'),
        }
      } else {
        modalConfig = {
          isShown: true,
          modalContent: t('formRunner.fieldView.fill.nodata.description'),
          headerText: t('formRunner.fieldView.fill.nodata.title'),
        }
      }
    } catch (error: any) {
      const errorMessage = error?.networkError?.result?.message
      modalConfig = {
        isShown: true,
        modalContent: errorMessage.includes('JSON')
          ? t('formRunner.fieldView.fill.error.description.extract')
          : errorMessage ||
            t('formRunner.fieldView.fill.error.description.unknown'),
        headerText: t('formRunner.fieldView.fill.error.title'),
      }
    } finally {
      setScanning(false)
      setDisplayModal(modalConfig)
    }
  }

  const tooltipTitle = useMemo(
    () =>
      !documentsValid.length && documents.length
        ? t('formRunner.fieldView.fill.tooltip.wrongmimetype')
        : t('formRunner.fieldView.fill.tooltip.valid'),
    [documents, documentsValid, t]
  )

  return (
    <>
      <Tooltip placement="left" title={tooltipTitle}>
        <InnerButton
          disabled={!documentsValid.length}
          type={ButtonType.text}
          className="chat-box-button"
          icon={<AIIcon />}
          loading={scanning}
          onClick={onClick}
        />
      </Tooltip>
      {displayModal?.isShown && (
        <Modal
          {...displayModal}
          style={{
            mainStyle: {
              width: '40%',
            },
          }}
          hide={() => setDisplayModal({})}
        />
      )}
    </>
  )
}

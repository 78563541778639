import { isEqual } from 'lodash'
import {
  END_IF_REGEXP,
  END_LOOP_REGEXP,
  IF_REGEXP,
  LOOP_REGEXP,
} from '../../constants'
import { findIndexForValueX } from './findIndexForValueX'

// Define the expected structure of DocumentEditor
interface DocumentEditor {
  serialize(): string
  selection: {
    endOffset: string
  }
}

// Define the expected structure of the document node
interface DocumentNode {
  at?: string
  [key: string]: any
}

export const getLoopsSelectionInDocumentEditor = (
  documentEditor: DocumentEditor
) => {
  const sfdt: DocumentNode = JSON.parse(documentEditor.serialize())
  const selection = documentEditor.selection
  const offset = selection.endOffset.split(';').map(n => parseInt(n, 10))
  const loopsStack: string[] = [] // Stack to track nested loops
  const conditionsStack: string[] = [] // Stack to track nested conditions

  // Helper function to traverse and process EJS tags in a node
  const traverseAndProcess = (
    node: DocumentNode | null,
    path: number[]
  ): void => {
    // Check if we've exceeded the cursor's position in the document tree
    for (let i = 0; i < path.length; i++) {
      if (path[i] > (offset[i] || 0)) {
        return
      }
      if (path[i] < (offset[i] || 0)) {
        break
      }
    }

    // Stop traversal if we've passed the offset
    if (!node) {
      return
    }

    if (node.at) {
      // Process EJS tags in node.at
      const ejsLoop = [...node.at.matchAll(LOOP_REGEXP)]
      const ejsEndLoop = [...node.at.matchAll(END_LOOP_REGEXP)]
      const ejsIf = [...node.at.matchAll(IF_REGEXP)]
      const ejsEndIf = [...node.at.matchAll(END_IF_REGEXP)]

      // Push each start loop or condition occurrence onto the stack
      if (ejsLoop.length) {
        loopsStack.push(ejsLoop[0][1].split('[')[0]) // Track loop start
      }
      if (ejsIf.length) {
        conditionsStack.push(ejsIf[0][1].split('[')[0]) // Track condition start
      }

      // Pop each end loop or condition occurrence from the stack
      if (ejsEndLoop.length) {
        loopsStack.pop() // Track loop end
      }
      if (ejsEndIf.length) {
        conditionsStack.pop() // Track condition end
      }
    }

    // Recursively process deeper nested elements in arrays or objects
    for (const key in node) {
      if (Array.isArray(node[key])) {
        let limitIndex = node[key].length - 1

        // Adjust limitIndex to avoid processing elements beyond the cursor's position
        if (isEqual(offset.slice(0, -1), path)) {
          limitIndex = offset[path.length]
          if (key === 'i') {
            limitIndex =
              findIndexForValueX(node[key], offset[offset.length - 1]) - 1
          }
        }

        // Ensure limitIndex is non-negative
        limitIndex = Math.max(limitIndex, 0)

        for (let i = 0; i <= limitIndex; i++) {
          // Check for array bounds
          if (i < node[key].length) {
            // Traverse nested array elements with updated block and inline indices
            traverseAndProcess(node[key][i], [...path, i])
          }
        }
      }
    }
  }

  traverseAndProcess(sfdt, [])
  // Return stack statuses to indicate if inside a loop or condition
  return { loopsStack, conditionsStack }
}

interface Condition {
  key?: string
  op: string
  value?: string
  formatted?: string
  condition?: Condition[]
}

interface Variable {
  name: string
  __parent?: { name: string }
}

const equivalent: Record<string, string> = {
  eq: '===',
  ne: '!=',
  lt: '<',
  le: '<=',
  gt: '>',
  ge: '>=',
}

export const stringifyConditions = (
  conditions: Condition,
  variables: Variable[]
): string => {
  function getValue(condition: Condition): string {
    const variable = variables.find(v => v.name === condition.key)
    const parent = variable ? variable.__parent : undefined
    const prefix = parent?.name
      ? `${parent.name.split('[')[0].slice(0, -1)}.`
      : ''

    const path: string[] = []

    if (condition.formatted) {
      if (condition.op && condition.value) {
        path.push(
          `getArray("${condition.formatted}").length${equivalent[condition.op]}${condition.value}`
        )
      }
    } else if (condition.op === 'inc' && condition.key && condition.value) {
      path.push(
        `${prefix}get('${condition.key}').includes('${condition.value}')`
      )
    } else if (condition.op === 'ninc' && condition.key && condition.value) {
      path.push(
        `!${prefix}get('${condition.key}').includes('${condition.value}')`
      )
    } else if (condition.op === 'isSet' && condition.key) {
      path.push(`${prefix}get('${condition.key}')`)
    } else if (condition.op === 'isNotSet' && condition.key) {
      path.push(`!${prefix}get('${condition.key}')`)
    } else if (condition.key && condition.op && condition.value) {
      const keyPath = variable
        ? `${prefix}get('${condition.key}')`
        : condition.key
      path.push(keyPath, equivalent[condition.op], `'${condition.value}'`)
    }

    return path.join(' ')
  }

  function processConditions(conditions: Condition): string {
    const path: string[] = []
    const isOrCondition = conditions.op === 'or'

    conditions.condition?.forEach(condition => {
      if (condition.op === 'and' || condition.op === 'or') {
        path.push('(' + processConditions(condition) + ')')
      } else {
        path.push(getValue(condition))
      }
    })

    return isOrCondition ? path.join(' || ') : path.join(' && ')
  }

  return '(' + processConditions(conditions) + ')'
}

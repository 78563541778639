import gql from 'graphql-tag'

export const getFiletypesQuery = gql`
  query getFiletypes($applicationId: ID) {
    getFiletypes(applicationId: $applicationId) {
      id
      title
    }
  }
`

export const CONTENT_TYPE_HTML = 'text/html'
export const CONTENT_TYPE_PDF = 'application/pdf'

export const CONTENT_TYPE_SYNCFUSION_DOCUMENT_TEXT =
  'application/vnd.syncfusion.application/json.document.text'
export const CONTENT_TYPE_DOC = 'application/msword'
export const CONTENT_TYPE_DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

export const WORD_DOCUMENT_TYPES = [CONTENT_TYPE_DOC, CONTENT_TYPE_DOCX]

export const GDPR_FILE_TYPES = [
  'bankAccountDetails',
  'certificateBenificialOwnersList',
  'certificateDPAE',
  'certificateFilingForPersonOfSignificantControl',
  'certificateMaritalStatus',
  'criminalRecordExtract',
  'diploma',
  'directorSalary',
  'driverLicensedriverLicense',
  'criminalStatement',
  'idCard',
  'payslip',
  'passport',
  'proofOfResidency',
  'socialSecurityCard',
]

const moment = require('moment')

exports.DATE_TYPES = {
  get now() {
    return moment().toISOString(true)
  },
  get lastNewYearEve() {
    return moment()
      .year(moment().year() - 1)
      .month(11)
      .date(31)
      .toISOString(true)
  },
}

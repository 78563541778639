import React from 'react'
import ZoomOutIcon from '../Icon/ZoomOutIcon'
import ZoomInIcon from '../Icon/ZoomInIcon'
import { EllipsisTooltip } from '../EllipsisTooltip/EllipsisTooltip'
import { Arrow } from '../Arrow/Arrow'

interface ViewerHeaderProps {
  scale?: number
  extra?: React.ReactNode
  type?: 'sfdt' | 'pdf' | string
  shortPage?: boolean
  onSetScale?: (scale: number) => void
  filename?: string
  onPreviousClick?: () => void
  onNextClick?: () => void
}

export const ViewerHeader: React.FC<ViewerHeaderProps> = ({
  scale,
  extra,
  type,
  shortPage = false,
  onSetScale,
  filename,
  onPreviousClick,
  onNextClick,
}) => {
  return (
    <div
      className="viewer-header"
      style={{
        gridTemplateColumns: '1fr 3fr 1fr',
      }}
    >
      {extra ? <div className="viewer-header-left">{extra}</div> : <div></div>}

      {shortPage ? (
        <div />
      ) : (
        <div className="viewer-header-center">
          {typeof onPreviousClick === 'function' && (
            <Arrow direction="left" onClick={onPreviousClick} />
          )}

          <div className="viewer-header-title">
            {Boolean(filename) && (
              <EllipsisTooltip placement="top" line={2} limit={400}>
                <>{filename && filename.replace(/\.sfdt$/, '.docx')}</>
              </EllipsisTooltip>
            )}
          </div>

          {typeof onNextClick === 'function' && (
            <Arrow direction="right" onClick={onNextClick} />
          )}
        </div>
      )}

      {type === 'pdf' && (
        <div className="viewer-header-right">
          {scale !== undefined && !Number.isNaN(scale) && (
            <div>{Math.round(scale * 100) + '%'}</div>
          )}
          <button
            title="zoomout"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onSetScale?.(0)
            }}
          >
            <ZoomOutIcon />
          </button>
          <button
            title="zoomin"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onSetScale?.(1)
            }}
          >
            <ZoomInIcon />
          </button>
        </div>
      )}
    </div>
  )
}
